import base64 from 'react-native-base64'
import * as serverConstants from '../constants/ServerConstants'
import * as cacheUtil from '../util/CacheUtility'

export function getWebToken(payload) {
    return fetch(window.location.origin + serverConstants.ANDROID_COMMAND_URL, {
        "method": "POST",
        "headers": {
            "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
        },
        "body": JSON.stringify({
            path_param: "/webTokens",
            payload: base64.encode(JSON.stringify(payload))
        })
    }).then((response) => {
        return response
    })
}