import base64 from 'react-native-base64'
import * as serverConstants from '../constants/ServerConstants'
import * as constants from '../constants/Constants'
import * as cacheUtil from '../util/CacheUtility'

export function getEnterPriseRegDetails() {
    return fetch(window.location.origin + serverConstants.ADMIN_COMMAND_URL, {
        "method": "POST",
        "headers": {
            "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
        },
        "body": JSON.stringify({
            path_param: "enterprises",
            target: "mdm",
            requestType: "GET"
        })
    }).then((response) => {
        return response
    })
}

export function getDPCExtras() {
    var payload = {
        "policyName": serverConstants.DEFAULT_POLICY,
        "duration": serverConstants.DURATION_IN_SECONDS
    }
    return fetch(window.location.origin + serverConstants.ANDROID_COMMAND_URL, {
        "method": "POST",
        "headers": {
            "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
        },
        "body": JSON.stringify({
            path_param: "/enrollmentTokens",
            payload: base64.encode(JSON.stringify(payload))
        })
    }).then((response) => {
        return response
    })
}

export function updateEnterpriseSigninDetail(enterpriseDisplayName) {
    fetch(window.location.origin + serverConstants.ADMIN_COMMAND_URL, {
        "method": "POST",
        "headers": {
            "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
        },
        "body": getBody(enterpriseDisplayName)
    })
}

function getBody(enterpriseDisplayName) {
    return JSON.stringify({
        path_param: "enterprises",
        target: "mdm",
        payload: base64.encode(JSON.stringify(getPayLoad(enterpriseDisplayName))),
        requestType: constants.HTTP_PATCH,
        query_param: { "updateMask": constants.SIGNIN_DETAILS + "," + constants.ENTERPRISE_DISPLAY_NAME }
    })
}

function getPayLoad(enterpriseDisplayName) {
    return {
        "signinDetails": [
            {
                "signinUrl": window.location.origin + constants.ENROLL_TYPE_PARAM + constants.ENROLL_TYPE_ZTD
            }
        ],
        "enterpriseDisplayName": enterpriseDisplayName
    }
}