import React from 'react'
/* import './App.css' */
import MainApp from './app/MainApp'

function App() {
  return (
    <div>
      <MainApp />
    </div>
  );
}

export default App;
