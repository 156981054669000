import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Login from './login/Login'
import Main from './androidsettings/Main'
import EnrollWithOutLdap from './enroll/enrollwithoutldap/EnrollWithOutLdap'
import EnrollWithLdap from './enroll/enrollwithldap/EnrollWithLdap'
import Configure from './initconfig/configure/Configure'
import EnrollWithAzure from './enroll/enrollwithazure/EnrollWithAzure'
import EnrollWithSaml from './enroll/enrollwithsaml/EnrollWithSaml'

function MainApp() {
    return (
        <Router>
            <Switch>
                <Route exact path='/'>
                {window.globalConfig.LDAP_AUTH ? (window.globalConfig.SAML_AUTH ? <EnrollWithSaml/> : (window.globalConfig.AZURE_AUTH ? <EnrollWithAzure /> : <EnrollWithLdap />)) : <EnrollWithOutLdap />}
                </Route>
                <Route exact path='/config'>
                    <Login service='config' />
                </Route>
                <Route exact path='/kibana'>
                    <Login service='kibana' />
                </Route>
                <Route exact path='/consul'>
                    <Login service='consul' />
                </Route>
                <Route exact path='/config/enterprise'>
                    <Main />
                </Route>
                {window.globalConfig.CLOUD_DEPLOYMENT && <Route exact path='/initconfig'>
                    <Login service='initconfig' />
                </Route>}
                <Route exact path='/initconfig/settings'>
                    <Configure />
                </Route>
            </Switch>
        </Router>
    );
}

export default MainApp