import '@patron/patron-css/patron/index.css'
import React from "react"
import { Modal } from "@patron/patron-react/modal"
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import * as cacheUtil from '../util/CacheUtility'
import * as serverConstants from '../constants/ServerConstants'

function LogoutModal(props) {

    const { t } = useTranslation()
    const history = useHistory();
    const navigateTo = () => {
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        history.push(props.logoutUrl)
    }

    function setModalClose() {
        props.setModalClose(false)
    }

    return (
        <React.Fragment>
            <Modal
                actions={[
                    {
                        label: t('yes'),
                        type: 'primary',
                        handler: navigateTo

                    },
                    {
                        label: t('no'),
                        type: 'secondary',
                        handler: setModalClose
                    }
                ]}
                heading={t('logout')}
                onClose={setModalClose}
                type="warning"
            >
                <p>
                    {t('logoutModalDesc')}
                </p>
            </Modal>
        </React.Fragment>
    )
}

export default LogoutModal