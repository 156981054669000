import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { loadCatalogs } from './bfI18n/bfI18Utils'
import { BfI18NProvider } from 'bfcore-react'

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./app/util/AzureADAuthConfig";
import { MsalProvider } from "@azure/msal-react";

const APP_NAME = 'Mdm-UI'

loadCatalogs(APP_NAME)
const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BfI18NProvider>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BfI18NProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

document
  .querySelector('link[rel=manifest]')
  .setAttribute('crossorigin', 'use-credentials')
