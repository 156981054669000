import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { Toast } from "@patron/patron-react/toast"
import { Checkbox } from "@patron/patron-react/checkbox"

import './Automatic.scss'
import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as constants from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as cacheUtil from '../../util/CacheUtility'
import * as webTokenUtil from '../../util/WebTokenUtil'
import * as enterpriseUtil from '../../util/EnterpriseUtility'
import * as ErrorUtil from '../../util/ErrorUtility'

class Automatic extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false,
            webToken: '',
            isAuthenticated: true,
            error: false,
            errorMsg: '',
            enableUserAuthentication: false,
            copyTextValue: ''
        }
    }

    componentDidMount() {
        if (cacheUtil.readFromCache(constants.AUTOMATIC_USER_AUTHENTICATION)) {
            this.setState({ enableUserAuthentication: cacheUtil.readFromCache(constants.AUTOMATIC_USER_AUTHENTICATION).toLowerCase() === constants.TRUE })
        }
        this.getEnterpriseWebToken()
    }

    getEnterpriseWebToken() {
        this.setState({ showSpinner: true })
        var payload = {
            "parentFrameUrl": window.location.href,
            "enabledFeatures": [
                "ZERO_TOUCH_CUSTOMER_MANAGEMENT"
            ]
        }
        webTokenUtil.getWebToken(payload)
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (response.status === error.HTTP_INTERNAL_SERVER_ERROR) this.handleInternalServerErrorResponse()
                else if (response.status === error.HTTP_FAILED_DEPENDENCY) ErrorUtil.handleFailedDependency(this, response)
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ error: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                this.processGetEnterpriseWebTokenAPIResponse(response)
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    handleInternalServerErrorResponse() {
        this.setState({ showSpinner: false })
        this.setState({ error: true })
        this.setState({ errorMsg: error.HTTP_INTERNAL_SERVER_ERROR + ": " + this.props.t('missingEnterpriseCredentials') })
        return false
    }

    defaultError(error) {
        this.setState({ showSpinner: false })
        this.setState({ error: true })
        this.setState({ errorMsg: error })
        return false
    }

    processGetEnterpriseWebTokenAPIResponse(response) {
        if (response) {
            let responseData = Buffer.from(response.response, "base64").toString()
            responseData = JSON.parse(responseData)
            this.setState({ webToken: responseData.value })
            this.getDPCExtras()
        }
    }

    getDPCExtras() {
        this.setState({ showSpinner: true })
        enterpriseUtil.getDPCExtras()
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (response.status === error.HTTP_INTERNAL_SERVER_ERROR) this.handleInternalServerErrorResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ error: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                this.processGetDPCExtrasAPIResponse(response)
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    processGetDPCExtrasAPIResponse(response) {
        if (response) {
            let responseData = Buffer.from(response.response, "base64").toString()
            responseData = JSON.parse(responseData)
            let jsonDpcExtras = JSON.parse(responseData.qrCode)
            this.setState({ copyTextValue: JSON.stringify(jsonDpcExtras) })
            if (this.state.enableUserAuthentication) this.getSigninEnrollmentToken()
            else this.invokeZeroTouchIFrame()
        }
    }

    invokeZeroTouchIFrame() {
        var baseurl = serverConstants.IFRAME_BASE_URL
        var appName = serverConstants.IFRAME_APP_NAME
        var generated_url = baseurl + this.state.webToken + "&dpcId=" + appName + "&dpcExtras=" + this.state.copyTextValue
        window.gapi.load('gapi.iframes', function () {
            var options = {
                'url': generated_url,
                'where': document.getElementById('iframe-container'),
                'attributes': { style: 'width: 100%; height:32.5rem', scrolling: 'yes' }
            }
            var iframe = window.gapi.iframes.getContext().openChild(options)
            iframe.register('onconfigupdated',
                function (event) {
                    console.log(event)
                }, window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER)
        })
        this.setState({ showSpinner: false })
    }

    enableUserAuthentication() {
        this.setState({ enableUserAuthentication: !this.state.enableUserAuthentication })
        if (!this.state.enableUserAuthentication) {
            cacheUtil.storeToCache(constants.AUTOMATIC_USER_AUTHENTICATION, !this.state.enableUserAuthentication)
            this.getSigninEnrollmentToken()
        } else {
            cacheUtil.clearCacheItem(constants.AUTOMATIC_USER_AUTHENTICATION)
            this.getDPCExtras()
        }
    }

    getSigninEnrollmentToken() {
        this.setState({ showSpinner: true })
        enterpriseUtil.getEnterPriseRegDetails()
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ error: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                let responseData = Buffer.from(response.response, "base64").toString()
                responseData = JSON.parse(responseData)
                if (responseData) {
                    if (responseData.enterpriseDetails) {
                        var enterpriseDetails = JSON.parse(responseData.enterpriseDetails)
                        if (enterpriseDetails.signinDetails) {
                            var signinEnrollmentToken = enterpriseDetails.signinDetails[0].signinEnrollmentToken
                            var copyTextValue = JSON.parse(this.state.copyTextValue)
                            copyTextValue[constants.PROVISIONING_ADMIN_EXTRAS_BUNDLE][constants.EXTRA_ENROLLMENT_TOKEN] = signinEnrollmentToken
                            this.setState({ copyTextValue: JSON.stringify(copyTextValue) })
                            this.invokeZeroTouchIFrame()
                        }
                    }
                    this.setState({ showSpinner: false })
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {!this.state.isAuthenticated && <Redirect to='/config?session=false' />}
            {this.state.error && <Toast
                iconDescription="close"
                className="toast-align"
                title={this.state.errorMsg}
                type="danger"
                visible
                closable
                onClose={() => { this.setState({ error: false }) }}
            />}
            <div className="mt-2">
                <Checkbox
                    id="enableUserAuthentication"
                    label={this.props.t('enableUserAuthentication')}
                    checked={this.state.enableUserAuthentication}
                    onChange={() => {
                        this.enableUserAuthentication()
                    }}
                />
            </div>
            <div id="iframe-container">

            </div>
        </React.Fragment >)
    }
}

export default withTranslation()(Automatic)