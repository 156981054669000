/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import de from '../locales/de.json'
import en from '../locales/en.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'
import it from '../locales/it.json'
import ja from '../locales/ja.json'
import ko from '../locales/ko.json'
import ptbr from '../locales/pt-br.json'
import zhcn from '../locales/zh-cn.json'
import zhtw from '../locales/zh-tw.json'

import { bfi18n } from 'bfcore-react'

const catalogs = {
  de: de,
  en: en,
  es: es,
  fr: fr,
  it: it,
  ja: ja,
  ko: ko,
  'pt-BR': ptbr,
  'zh-CN': zhcn,
  'zh-TW': zhtw
}

export const DEFAULT_LANGUAGES = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt-BR',
  'zh-CN',
  'zh-TW'
]

export const DEFAULT_LANGUAGE = 'en'

export function loadCatalogs(appName) {
  DEFAULT_LANGUAGES.forEach((language) => {
    bfi18n.addResourceBundle(language, appName, catalogs[language])
  })
  bfi18n.setDefaultNamespace(appName)
}
