import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CodeSnippet } from "@patron/patron-react/codesnippet"
import { Redirect } from 'react-router'
import { Label } from "@patron/patron-react/label"
import { Toast } from "@patron/patron-react/toast"
import { Checkbox } from "@patron/patron-react/checkbox"

import './Manual.scss'
import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as constants from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as cacheUtil from '../../util/CacheUtility'
import * as enterpriseUtil from '../../util/EnterpriseUtility'
import * as ErrorUtil from '../../util/ErrorUtility'

class Manual extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false,
            copyTextValue: '',
            isAuthenticated: true,
            error: false,
            errorMsg: '',
            enableUserAuthentication: false
        }
    }

    componentDidMount() {
        this.getDPCExtras()
    }

    getDPCExtras() {
        this.setState({ showSpinner: true })
        enterpriseUtil.getDPCExtras()
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (response.status === error.HTTP_INTERNAL_SERVER_ERROR) this.handleInternalServerErrorResponse()
                else if (response.status === error.HTTP_FAILED_DEPENDENCY) ErrorUtil.handleFailedDependency(this, response)
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ copyTextValue: '' })
                    this.setState({ error: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                this.processGetDPCExtrasAPIResponse(response)
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    handleInternalServerErrorResponse() {
        this.setState({ showSpinner: false })
        this.setState({ copyTextValue: '' })
        this.setState({ error: true })
        this.setState({ errorMsg: error.HTTP_INTERNAL_SERVER_ERROR + ": " + this.props.t('missingEnterpriseCredentials') })
        return false
    }

    defaultError(error) {
        this.setState({ showSpinner: false })
        this.setState({ copyTextValue: '' })
        this.setState({ error: true })
        this.setState({ errorMsg: error })
        return false
    }

    processGetDPCExtrasAPIResponse(response) {
        if (response) {
            let responseData = Buffer.from(response.response, "base64").toString()
            responseData = JSON.parse(responseData)
            let jsonDpcExtras = JSON.parse(responseData.qrCode)
            this.setState({ copyTextValue: JSON.stringify(jsonDpcExtras, null, 2) })
            this.setState({ showSpinner: false })
        }
    }

    enableUserAuthentication() {
        this.setState({ enableUserAuthentication: !this.state.enableUserAuthentication })
        if (!this.state.enableUserAuthentication) {
            this.getSigninEnrollmentToken()
        } else {
            this.getDPCExtras()
        }
    }

    getSigninEnrollmentToken() {
        this.setState({ showSpinner: true })
        enterpriseUtil.getEnterPriseRegDetails()
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ error: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                let responseData = Buffer.from(response.response, "base64").toString()
                responseData = JSON.parse(responseData)
                if (responseData) {
                    if (responseData.enterpriseDetails) {
                        var enterpriseDetails = JSON.parse(responseData.enterpriseDetails)
                        if (enterpriseDetails.signinDetails) {
                            var signinEnrollmentToken = enterpriseDetails.signinDetails[0].signinEnrollmentToken
                            var copyTextValue = JSON.parse(this.state.copyTextValue)
                            copyTextValue[constants.PROVISIONING_ADMIN_EXTRAS_BUNDLE][constants.EXTRA_ENROLLMENT_TOKEN] = signinEnrollmentToken
                            this.setState({ copyTextValue: JSON.stringify(copyTextValue, null, 2) })
                        }
                    }
                    this.setState({ showSpinner: false })
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {!this.state.isAuthenticated && <Redirect to='/config?session=false' />}
            {this.state.error && <Toast
                iconDescription="close"
                className="toast-align"
                title={this.state.errorMsg}
                type="danger"
                visible
                closable
                onClose={() => { this.setState({ error: false }) }}
            />}
            <div className="mt-2">
                <Checkbox
                    id="enableUserAuthentication"
                    label={this.props.t('enableUserAuthentication')}
                    checked={this.state.enableUserAuthentication}
                    onChange={() => {
                        this.enableUserAuthentication()
                    }}
                />
            </div>
            <div className="mt-3">
                <Label >
                    {this.props.t('dpcExtras')}
                </Label>
            </div>
            <div className="mt-2">
                <CodeSnippet
                    height="10rem"
                    width="100%"
                    onCopy={() => {
                        navigator.clipboard.writeText(this.state.copyTextValue)
                    }}
                    value={this.state.copyTextValue}
                />
            </div>
            <section className="mt-2 p-5 inline-section">
                <div>
                    <Label>
                        {this.props.t('stepToSetupZeroTouch')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        1. {this.props.t('stepToSetupZeroTouch1')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        2. {this.props.t('stepToSetupZeroTouch2')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        3. {this.props.t('stepToSetupZeroTouch3')}
                        &nbsp;<Link
                            to={{ pathname: serverConstants.MANUAL_ZERO_TOUCH_URL }}
                            target="_blank"
                            title={this.props.t('androidZeroTouchPortal')}
                            rel="noopener noreferrer"
                        >
                            {this.props.t('androidZeroTouchPortal')}
                        </Link>
                        &nbsp;{this.props.t('stepToSetupZeroTouch3.1')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        4. {this.props.t('stepToSetupZeroTouch4')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        5. {this.props.t('stepToSetupZeroTouch5')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        6. {this.props.t('stepToSetupZeroTouch6')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        7. {this.props.t('stepToSetupZeroTouch7')}
                    </Label>
                </div>
                <div className="mt-1 ml-5">
                    <Label>
                        8. {this.props.t('stepToSetupZeroTouch8')}
                    </Label>
                </div>
            </section>
        </React.Fragment >)
    }
}

export default withTranslation()(Manual)