import Cookies from 'universal-cookie';

const cookies = new Cookies();

//storeCache Utility function to set cache item
export function storeToCache(key, value) {
    localStorage.setItem(key, value)
}

//readCache Utility function to read from cache
export function readFromCache(key) {
    return localStorage.getItem(key)
}

//clearCookie Utility function to remove cookie name
export function clearCookie(cookieName) {
    cookies.set(cookieName, "", { path: '/', expires: new Date(Date.now()) });
}

//clearCacheItem Utility function to remove cache item
export function clearCacheItem(itemName) {
    localStorage.removeItem(itemName)
}

//saveCookie Utility function to read from cookie
export function saveCookie(name, value, exp) {
    cookies.set(name, value, { path: '/', expires : exp });
}

//getCookie Utility function to read from cookie
export function getCookie(key) {
    return cookies.get(key)
}

//removeCookie Utility function to remove cookie name
export function removeCookie(cookieName) {
    cookies.remove(cookieName)
}
