import * as constants from '../constants/Constants'
import BigfixLogo from "../../static/img/Fantastic_Bigfix_Logo.png"

export function setBrowserTab() {
    document.title = setFaviconTittle()
    const favicon = document.getElementById("rebrandingIcon")
    favicon.href = window.barandConfig.faviconImageFile && window.barandConfig.faviconImageFile !== constants.EMPTY ? window.barandConfig.faviconImageFile : process.env.PUBLIC_URL + constants.FAVICON_BIGFIX_IMAGE_FILE
}

export function rebrandingUIBackgroundStyle() {
    const rebrandingUIStyle = {
        background: isPageBackgroundImageConfigured() ? 'url(' + window.barandConfig.pageBackgroundImageFile + ') no-repeat' : '',
        backgroundSize: isPageBackgroundImageConfigured() ? '100vh 85vh' : '',
        backgroundPosition: isPageBackgroundImageConfigured() ? 'center' : ''
    }
    return rebrandingUIStyle
}

export function setCopyrightMessage(t) {
    return window.barandConfig.copyrightMessage && window.barandConfig.copyrightMessage !== constants.EMPTY ? window.barandConfig.copyrightMessage : t('copyrightHCL') + " " + constants.HCL_COPYRIGHT_YEAR + ". " + t('allRightsReserved')
}

export function setCompanyLogoBg() {
    return window.barandConfig.companyLogoBg && window.barandConfig.companyLogoBg !== constants.EMPTY ? window.barandConfig.companyLogoBg : '';
}

export function setBrandLogoPanelBg() {
    return window.barandConfig.brandLogoPanelBg && window.barandConfig.brandLogoPanelBg !== constants.EMPTY ? window.barandConfig.brandLogoPanelBg : ''
}

export function setButtonColor() {
    return window.barandConfig.buttonColor && window.barandConfig.buttonColor !== constants.EMPTY ? window.barandConfig.buttonColor : '';
}

export function setFaviconTittle() {
    return window.barandConfig.faviconTittle && window.barandConfig.faviconTittle !== constants.EMPTY ? window.barandConfig.faviconTittle : constants.BIGFIX;
}

export function setBrandLogo() {
    return isBrandLogoImageConfigured() ? window.barandConfig.brandLogoImageFile : BigfixLogo;
}

export function getCompanyLogoImage() {
    return isCompanyLogoImageConfigured() ? window.barandConfig.companyLogoImageFile : '';
}

export function getPageBackgroundImage() {
    return isPageBackgroundImageConfigured() ? window.barandConfig.pageBackgroundImageFile : '';
}

export function getFaviconImage() {
    return isFaviconImageConfigured() ? window.barandConfig.faviconImageFile : '';
}

export function getBrandLogoImage() {
    return isBrandLogoImageConfigured() ? window.barandConfig.brandLogoImageFile : '';
}

export function isPageBackgroundImageConfigured() {
    return window.barandConfig.pageBackgroundImageFile && window.barandConfig.pageBackgroundImageFile !== constants.EMPTY;
}

export function isBrandLogoImageConfigured() {
    return window.barandConfig.brandLogoImageFile && window.barandConfig.brandLogoImageFile !== constants.EMPTY;
}

export function isFaviconImageConfigured() {
    return window.barandConfig.faviconImageFile && window.barandConfig.faviconImageFile !== constants.EMPTY;
}

export function isCompanyLogoImageConfigured() {
    return window.barandConfig.companyLogoImageFile && window.barandConfig.companyLogoImageFile !== constants.EMPTY;
}