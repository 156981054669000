import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { withTranslation } from 'react-i18next'
import { SketchPicker } from 'react-color'
import base64 from 'react-native-base64'
import { Redirect } from 'react-router'
import { Heading } from "@patron/patron-react/heading"
import { Label } from "@patron/patron-react/label"
import { FileUploader } from "@patron/patron-react/fileuploader"
import { TextInput } from "@patron/patron-react/textinput"
import { Button } from "@patron/patron-react/button"
import { Toast } from "@patron/patron-react/toast"
import { Modal } from "@patron/patron-react/modal"

import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as Constant from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as cacheUtil from '../../util/CacheUtility'
import * as ErrorUtil from '../../util/ErrorUtility'
import * as rebrandingUtil from '../../util/RebrandingUtility'

class RebrandingUI extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyLogoBg: rebrandingUtil.setCompanyLogoBg(),
            companyLogo: '',
            companyLogoImageFile: rebrandingUtil.getCompanyLogoImage(),
            brandLogo: '',
            brandLogoImageFile: rebrandingUtil.getBrandLogoImage(),
            copyrightMessage: rebrandingUtil.setCopyrightMessage(this.props.t),
            brandLogoPanelBg: rebrandingUtil.setBrandLogoPanelBg(),
            buttonColor: rebrandingUtil.setButtonColor(),
            pageBackground: '',
            pageBackgroundImageFile: rebrandingUtil.getPageBackgroundImage(),
            faviconImage: '',
            faviconImageFile: rebrandingUtil.getFaviconImage(),
            faviconTittle: rebrandingUtil.setFaviconTittle(),
            validCompanyLogoFileExt: false,
            validCompanyLogoFileSize: false,
            validBrandLogoFileExt: false,
            validPageBackgroundFileExt: false,
            validFaviconImageFileExt: false,
            showSpinner: false,
            showError: false,
            errorMsg: '',
            isAuthenticated: true,
            showConfigModal: false,
            isConfiguration: false,
        }
    }

    handleHeaderChangeColor = (color) => {
        this.setState({ companyLogoBg: color.hex })
    }

    handleBrandLogoPanelColor = (color) => {
        this.setState({ brandLogoPanelBg: color.hex })
    }

    handleButtonChangeColor = (color) => {
        this.setState({ buttonColor: color.hex })
    }

    configureOrReset() {
        this.setState({ showSpinner: true })
        this.setState({ showConfigModal: false })
        fetch(window.location.origin + serverConstants.ADMIN_REBRANDING_UI_URL, {
            "method": this.state.isConfiguration ? Constant.HTTP_POST : Constant.HTTP_PATCH,
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
            "body": JSON.stringify({
                path_param: Constant.REBRANDING,
                payload: base64.encode(JSON.stringify(this.state))
            })
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    this.setState({ showSpinner: false })
                    window.location.replace('/config')
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    defaultError(error) {
        this.setState({ showSpinner: false })
        this.setState({ showError: true })
        this.setState({ errorMsg: error })
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    getBase64(file, cb) {
        let reader = new FileReader()
        reader.onload = function () {
            cb(reader.result)
        }
        reader.onerror = function () {
            this.uploadTokenError()
        }
        reader.readAsDataURL(file)
    }

    uploadTokenError() {
        this.setState({ showError: true })
        this.setState({ errorMsg: this.props.t('uploadTokenErrorMsg') })
    }

    setModalClose() {
        this.setState({ showConfigModal: false })
    }

    scrollToTop() {
        const divToScrollTo = document.getElementById('containerTop')
        if (divToScrollTo) {
            divToScrollTo.scrollIntoView()
        }
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {!this.state.isAuthenticated && <Redirect to='/config?session=false' />}
            {this.state.showConfigModal && <Modal
                actions={[
                    {
                        label: this.props.t('okButton'),
                        type: "primary",
                        handler: () => this.configureOrReset()
                    },
                    {
                        label: this.props.t('cancel'),
                        type: 'secondary',
                        handler: () => this.setModalClose()
                    }
                ]}
                heading={this.state.isConfiguration ? this.props.t('configure') : this.props.t('reset')}
                onClose={() => { this.setModalClose() }}
                type="warning"
            >
                <p>
                    {this.state.isConfiguration ? this.props.t('rebrandingConfigModalDesc') : this.props.t('rebrandingResetModalDesc')}
                </p>
            </Modal>}
            {this.state.showError && <Toast
                iconDescription="close"
                className="toast-align"
                title={this.state.errorMsg}
                type="danger"
                visible
                closable
                onClose={() => { this.setState({ showError: false }) }}
            />}
            <div className="hcl-container" id='containerTop'>
                <div className="mt-4">
                    <Heading type="h4" >
                        {this.props.t('rebrandingTheUIScreen')}
                    </Heading>
                </div>
                <form>
                    <div id="companyLogoDetails">
                        <section className="mt-2 parameters-section">
                            <Label className="mt-2 ml-1">{this.props.t('companyLogoHeader')}</Label>
                            <div className="hcl-row">
                                <div className="mt-2 ml-2 hcl-col-6 hcl-form-group">
                                    <FileUploader
                                        className="hcl-btn hcl-primary hcl-sm"
                                        id="companyLogoFile"
                                        label={this.props.t('companyLogo')}
                                        onChange={(files) => {
                                            this.setState({ validCompanyLogoFileExt: false })
                                            this.setState({ validCompanyLogoFileSize: false })
                                            var file = files[0]
                                            if (file) {
                                                var fileExt = file.name.split('.').pop()
                                                if (fileExt !== "png") {
                                                    this.setState({ validCompanyLogoFileExt: true })
                                                } else {
                                                    this.getBase64(file, (result) => {
                                                        var image = new Image()
                                                        image.src = result
                                                        image.onload = () => {
                                                            this.setState({ validCompanyLogoFileSize: image.height > 40 && image.width > 100 })
                                                            if (image.height <= 40 && image.width <= 100) {
                                                                this.setState({ companyLogo: result })
                                                                this.setState({ companyLogoImageFile: window.location.origin + "/static/media/Company_Logo.png" })
                                                            }
                                                        }
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        {this.props.t('uploadFile')}
                                    </FileUploader>
                                    {this.state.validCompanyLogoFileExt &&
                                        <Label className="ext-label-style">
                                            {this.props.t('fileExtensionNotAllowedForPng')}
                                        </Label>
                                    }
                                    {this.state.validCompanyLogoFileSize &&
                                        <Label className="ext-label-style">
                                            {this.props.t('fileSizeNotAllowedForPng')}
                                        </Label>
                                    }
                                    {(rebrandingUtil.isCompanyLogoImageConfigured()) &&
                                        <Label className="label-style">
                                            Company_Logo.png
                                        </Label>
                                    }
                                </div>
                                <div className="ml-2 hcl-col-4 hcl-form-group">
                                    <Label className="mt-3">{this.props.t('companyLogoBG')}</Label>
                                    <SketchPicker
                                        color={this.state.companyLogoBg}
                                        onChangeComplete={this.handleHeaderChangeColor}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                    <div id="wrapperDetails">
                        <section className="mt-2 parameters-section">
                            <Label className="mt-2 ml-1">{this.props.t('brandLogoCenterPanel')}</Label>
                            <div className="hcl-row">
                                <div className="mt-2 ml-2 hcl-col-6 hcl-form-group">
                                    <FileUploader
                                        className="hcl-btn hcl-primary hcl-sm"
                                        id="brandLogoFile"
                                        label={this.props.t('brandLogo')}
                                        onChange={(files) => {
                                            this.setState({ validBrandLogoFileExt: false })
                                            var file = files[0]
                                            if (file) {
                                                var fileExt = file.name.split('.').pop()
                                                if (fileExt !== "png") {
                                                    this.setState({ validBrandLogoFileExt: true })
                                                } else {
                                                    this.getBase64(file, (result) => {
                                                        this.setState({ brandLogo: result })
                                                        this.setState({ brandLogoImageFile: window.location.origin + "/static/media/Brand_Logo.png" })
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        {this.props.t('uploadFile')}
                                    </FileUploader>
                                    {this.state.validBrandLogoFileExt &&
                                        <Label className="ext-label-style">
                                            {this.props.t('fileExtensionNotAllowedForPng')}
                                        </Label>
                                    }
                                    {(rebrandingUtil.isBrandLogoImageConfigured()) &&
                                        <Label className="label-style">
                                            Brand_Logo.png
                                        </Label>
                                    }
                                </div>
                                <div className="ml-2 hcl-col-4 hcl-form-group">
                                    <Label className="mt-3">{this.props.t('brandLogoPanelBG')}</Label>
                                    <SketchPicker
                                        color={this.state.brandLogoPanelBg}
                                        onChangeComplete={this.handleBrandLogoPanelColor}
                                    />
                                </div>
                            </div>
                            <div className="hcl-row">
                                <div className="mt-2 ml-2 hcl-col-6 hcl-form-group">
                                    <TextInput
                                        type="text"
                                        placeholder="Copyright Text"
                                        id="copyrightMessage"
                                        value={this.state.copyrightMessage}
                                        onChange={(e) => {
                                            const value = e.currentTarget.value
                                            this.setState({ copyrightMessage: value })
                                        }}
                                        required
                                    />
                                    <Label htmlFor="copyrightMessage">{this.props.t('copyrightMessage')}</Label>
                                </div>
                                <div className="ml-2 hcl-col-4 hcl-form-group">
                                    <Label className="mt-3">{this.props.t('buttonColor')}</Label>
                                    <SketchPicker
                                        color={this.state.buttonColor}
                                        onChangeComplete={this.handleButtonChangeColor}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                    <div id="pageBackground">
                        <section className="mt-2 parameters-section">
                            <Label className="mt-2 ml-1">{this.props.t('pageBackground')}</Label>
                            <div className="hcl-row">
                                <div className="mt-2 ml-2 hcl-col-6 hcl-form-group">
                                    <FileUploader
                                        className="hcl-btn hcl-primary hcl-sm"
                                        id="pageBackgroundFile"
                                        label={this.props.t('pageBackgroundImage')}
                                        onChange={(files) => {
                                            this.setState({ validPageBackgroundFileExt: false })
                                            var file = files[0]
                                            if (file) {
                                                var fileExt = file.name.split('.').pop()
                                                if (fileExt !== "png") {
                                                    this.setState({ validPageBackgroundFileExt: true })
                                                } else {
                                                    this.getBase64(file, (result) => {
                                                        this.setState({ pageBackground: result })
                                                        this.setState({ pageBackgroundImageFile: window.location.origin + "/static/media/Page_Background.png" })
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        {this.props.t('uploadFile')}
                                    </FileUploader>
                                    {this.state.validPageBackgroundFileExt &&
                                        <Label className="ext-label-style">
                                            {this.props.t('fileExtensionNotAllowedForPng')}
                                        </Label>
                                    }
                                    {(rebrandingUtil.isPageBackgroundImageConfigured()) &&
                                        <Label className="label-style">
                                            Page_Background.png
                                        </Label>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                    <div id="pageTabOptions">
                        <section className="mt-2 parameters-section">
                            <Label className="mt-2 ml-1">{this.props.t('browserTab')}</Label>
                            <div className="hcl-row">
                                <div className="mt-2 ml-2 hcl-col-6 hcl-form-group">
                                    <FileUploader
                                        className="hcl-btn hcl-primary hcl-sm"
                                        id="pageTabFile"
                                        label={this.props.t('faviconImage')}
                                        onChange={(files) => {
                                            this.setState({ validFaviconImageFileExt: false })
                                            var file = files[0]
                                            if (file) {
                                                var fileExt = file.name.split('.').pop()
                                                if (fileExt !== "png") {
                                                    this.setState({ validFaviconImageFileExt: true })
                                                } else {
                                                    this.getBase64(file, (result) => {
                                                        this.setState({ faviconImage: result })
                                                        this.setState({ faviconImageFile: window.location.origin + "/static/media/Favicon_Image.png" })
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        {this.props.t('uploadFile')}
                                    </FileUploader>
                                    {this.state.validFaviconImageFileExt &&
                                        <Label className="ext-label-style">
                                            {this.props.t('fileExtensionNotAllowedForPng')}
                                        </Label>
                                    }
                                    {(rebrandingUtil.isFaviconImageConfigured()) &&
                                        <Label className="label-style">
                                            Favicon_Image.png
                                        </Label>
                                    }
                                </div>
                                <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                    <TextInput
                                        type="text"
                                        placeholder="Favicon Text"
                                        id="faviconTittle"
                                        value={this.state.faviconTittle}
                                        onChange={(e) => {
                                            const value = e.currentTarget.value
                                            this.setState({ faviconTittle: value })
                                        }}
                                        required
                                    />
                                    <Label htmlFor="faviconTittle">{this.props.t('faviconTittle')}</Label>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="hcl-row configure-button">
                        <div className="hcl-col-4 hcl-text-center mb-2">
                            <Button
                                className="hcl-secondary"
                                onClick={() => {
                                    this.scrollToTop()
                                    this.setState({ showConfigModal: true })
                                    this.setState({ isConfiguration: false })
                                }}
                            >
                                {this.props.t('reset')}
                            </Button>
                        </div>
                        <div className="hcl-col-8 hcl-text-center mb-2">
                            <Button
                                className="hcl-primary"
                                disabled={this.state.showSpinner || this.state.validCompanyLogoFileExt || this.state.validCompanyLogoFileSize ||
                                    this.state.validBrandLogoFileExt || this.state.validPageBackgroundFileExt || this.state.validFaviconImageFileExt}
                                onClick={() => {
                                    this.scrollToTop()
                                    this.setState({ showConfigModal: true })
                                    this.setState({ isConfiguration: true })
                                }}
                            >
                                {this.props.t('configure')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>)
    }
}

export default withTranslation()(RebrandingUI)