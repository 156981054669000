import '@patron/patron-css/patron/index.css'
import React from "react"
import { Header } from "@patron/patron-react/header"

import HclLogo from "../../static/img/Logo-HCL.png"
import * as Constant from '../constants/Constants'
import * as rebrandingUtil from '../util/RebrandingUtility'

function CompanyHeader() {
    return (<React.Fragment>
        <Header logo={<img src={rebrandingUtil.isCompanyLogoImageConfigured() ? rebrandingUtil.getCompanyLogoImage() : HclLogo} alt="Logo" type="none" />}
            style={{ background: rebrandingUtil.setCompanyLogoBg() }} />
    </React.Fragment>)
}

export default CompanyHeader