/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
function emailRecipient(mailTo) {
    return ('mailto:' + mailTo)
}

function emailSubject(subject) {
    return ('?subject=' + subject)
}

export function emailBody(body) {
    return ('&body=' + encodeURIComponent(body))
}

//Generates Email location
export function Email(recipient, subject, body) {
    return emailRecipient(recipient) + emailSubject(subject) + emailBody(body)
}

export function EmailAddressValidation(emailAddress) {
    if (emailAddress !== '' && !new RegExp(/[a-z0-9._%#+-a-z]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(emailAddress)) {
        return true
    }
    return false
}
