

export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/' + window.globalConfig.AZURE_TENANT_ID,
        clientId: window.globalConfig.AZURE_CLIENT_ID,
        redirectUri: '/',
        validateAuthority: true,
        navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"],
   prompt: 'select_account'
  };