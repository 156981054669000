import React, { useState, useEffect } from "react"
import '@patron/patron-css/patron/index.css'
import { TextInput } from "@patron/patron-react/textinput"
import { Label } from "@patron/patron-react/label"
import { Checkbox } from "@patron/patron-react/checkbox"
import { Password } from "@patron/patron-react/password"
import { Button } from "@patron/patron-react/button"
import { Paragraph } from "@patron/patron-react/paragraph"
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from "react-router-dom"
import base64 from 'react-native-base64'
import { Toast } from "@patron/patron-react/toast"

import "./Login.scss"
import CompanyHeader from "../companyheader/CompanyHeader"
import * as serverConstants from '../constants/ServerConstants'
import Spinner from '../spinner/Spinner'
import * as error from '../constants/ErrorCodes'
import * as cacheUtil from '../util/CacheUtility'
import * as ErrorUtil from '../util/ErrorUtility'
import * as Constant from '../constants/Constants'
import * as rebrandingUtil from '../util/RebrandingUtility'

const PREFIX = "app"

function Login(props) {
    const history = useHistory()
    const { t } = useTranslation()
    const [userName, setUserName] = useState(cacheUtil.readFromCache(Constant.USER_NAME) ? cacheUtil.readFromCache(Constant.USER_NAME) : '')
    const [password, setPassword] = useState(cacheUtil.readFromCache(Constant.PASSWORD) ? cacheUtil.readFromCache(Constant.PASSWORD) : '')
    const [rememberMe, setRememberPassword] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [session, setSession] = useState(false)


    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()

    const rememberMeHandler = () => {
        setRememberPassword(!rememberMe)
        if (rememberMe) {
            cacheUtil.clearCacheItem(Constant.REMEMBER_ME)
            cacheUtil.clearCacheItem(Constant.USER_NAME)
            cacheUtil.clearCacheItem(Constant.PASSWORD)
        }
    }

    useEffect(() => {
        if (cacheUtil.readFromCache(Constant.REMEMBER_ME)) {
            setRememberPassword(cacheUtil.readFromCache(Constant.REMEMBER_ME).toLowerCase() === Constant.TRUE)
        }
        rebrandingUtil.setBrowserTab()
        if (query.get('session') !== null && query.get('session') === "false") {
            setSession(true)
        }
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleSubmit()
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [query])

    const handleSubmit = () => {
        if (rememberMe) {
            rememberMeLocalStorage()
        }
        fetch(window.location.origin + serverConstants.ADMIN_LOGIN_URL, {
            "method": "POST",
            "headers": {
                "Authorization": 'Basic ' + base64.encode(userName + ":" + password)
            }
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED) {
                    setShowSpinner(false)
                    setShowError(true)
                    setErrorMsg(error.HTTP_UNAUTHORIZED + ": " + t('incorrectUsernamePassword'))
                    return false
                } else if (!response.ok) {
                    setShowSpinner(false)
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    return false
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    var serviceType = serverConstants.SERVICE_LOCATION_MAP[props.service]
                    if (serviceType.internal) {
                        cacheUtil.storeToCache(serverConstants.BFCONFIG_SID, response.token)
                        history.push(serviceType.link)
                    } else {
                        let dest = new URL(window.location.origin + serviceType.link, window.location)
                        window.location = dest.href
                    }
                    setShowSpinner(false)
                }
            })
            .catch(() => {
                handleErrorResponse()
            });
    }

    function rememberMeLocalStorage() {
        cacheUtil.storeToCache(Constant.REMEMBER_ME, rememberMe)
        cacheUtil.storeToCache(Constant.USER_NAME, userName)
        cacheUtil.storeToCache(Constant.PASSWORD, password)
    }

    function handleErrorResponse() {
        setShowSpinner(false)
        setShowError(true)
        setErrorMsg(t('unableToLogin'))
    }

    return (
        <React.Fragment>
            {showSpinner && <Spinner />}
            {session && <Toast
                iconDescription="close"
                title={t('sessionExpiredMsg')}
                type="warning"
                visible
            />}
            <CompanyHeader />
            <section className={`hcl-container hcl-flex p-0 ${PREFIX}-login-container`} style={rebrandingUtil.rebrandingUIBackgroundStyle()}>
                <div>
                    <div className={`${PREFIX}-login-wrapper`} style={{ background: rebrandingUtil.setBrandLogoPanelBg() }}>
                        <div className="hcl-text-center mb-4">
                            <img
                                src={rebrandingUtil.setBrandLogo()}
                                alt="product logo"
                            />
                        </div>
                        <form
                            className={`p-5 ${PREFIX}-login-form`}

                        >
                            <div className="hcl-form-group">
                                <TextInput
                                    type="text"
                                    placeholder={t('username')}
                                    id="username"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    required
                                />
                                <Label htmlFor="username">{t('username')}</Label>
                            </div>
                            <div className="hcl-form-group">
                                <Password
                                    placeholder={t('password')}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <Label htmlFor="password">{t('password')}</Label>
                            </div>
                            <div className={`hcl-flex mb-4 ${PREFIX}-login-options`}>
                                <Checkbox
                                    id="remember"
                                    label={t('rememberUser')}
                                    checked={rememberMe}
                                    onChange={rememberMeHandler}
                                />
                            </div>
                            {showError && (
                                <div className={`${PREFIX}-login-error-msg`}>
                                    {errorMsg}
                                </div>
                            )}
                            <div className="hcl-text-center">
                                <Button
                                    className="hcl-primary"
                                    style={{ background: rebrandingUtil.setButtonColor() }}
                                    disabled={showSpinner || userName === "" || password === ""}
                                    onClick={handleSubmit}
                                >
                                    {t('login')}
                                </Button>
                            </div>
                            <Paragraph className="hcl-type-legal mt-4">
                                &copy; {rebrandingUtil.setCopyrightMessage(t)}
                            </Paragraph>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Login