import * as ServerConstants from '../constants/ServerConstants'
import * as Constant from '../constants/Constants'

//getPlatformOS to get the OS where user is Enrolling
export function getPlatformOS() {
    let platformOS
    if (window.navigator.platform.toLowerCase().indexOf(Constant.PLATFORM_WIN) > -1) {
        platformOS = Constant.PLATFORM_WIN
    }
    if (window.navigator.platform.toLowerCase().indexOf(Constant.MAC) > -1) {
        platformOS = Constant.PLATFORM_APPLE
    }
    if (window.navigator.platform.toLowerCase().indexOf(Constant.IPHONE) > -1) {
        platformOS = Constant.PLATFORM_APPLE
    }
    if (window.navigator.platform.toLowerCase().indexOf(Constant.IPAD) > -1) {
        platformOS = Constant.PLATFORM_APPLE
    }
    if (window.navigator.userAgent.toLowerCase().indexOf(Constant.PLATFORM_ANDROID) > -1) {
        platformOS = Constant.PLATFORM_ANDROID
    }
    return platformOS
}

export function getServerURL() {
    let serverUrl
    if (getPlatformOS() === Constant.PLATFORM_WIN) {
        serverUrl = getWinServerURL()
    }
    if (getPlatformOS() === Constant.PLATFORM_APPLE) {
        serverUrl = window.location.origin + ServerConstants.MDM_ENROLL
    }
    if (getPlatformOS() === Constant.PLATFORM_ANDROID) {
        serverUrl = window.location.origin + ServerConstants.ANDROID_ENROLL
    }
    return serverUrl
}


export function getWinServerURL() {
    return ServerConstants.MS_DEVICE_ENROLL + window.location.origin + ServerConstants.WIN_ENROLL
}

export function IsQREnroll(qrEnroll) {
    if (qrEnroll === Constant.BYOD_FULLY_MANAGED || qrEnroll === Constant.DEDICATED || qrEnroll === Constant.COPE
        || qrEnroll === Constant.BRING_YOUR_OWN_DEVICE || qrEnroll === Constant.FULLY_MANAGED) {
        return true
    }
    return false
}

export function getQREnrollURlWithLDAP(qrEnroll, userName) {
    let serverUrl = window.location.origin + ServerConstants.ANDROID_QR_ENROLL_BASE_URL + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(userName) + ServerConstants.QR_ENROLLMENT_TYPE
    switch (qrEnroll) {
        case Constant.BRING_YOUR_OWN_DEVICE:
            return serverUrl = serverUrl + ServerConstants.BYOD_DEVICE
        case Constant.FULLY_MANAGED:
            return serverUrl = serverUrl + ServerConstants.FULLY_MANAGED
        case Constant.DEDICATED:
            return serverUrl = serverUrl + ServerConstants.DEDICATED_DEVICE
        case Constant.COPE:
            return serverUrl = serverUrl + ServerConstants.COPE_DEVICE
        default:
            return serverUrl
    }
}

export function getQREnrollURlWithoutLDAP(qrEnroll) {
    let serverUrl = window.location.origin + ServerConstants.ANDROID_QR_ENROLL_BASE_URL + ServerConstants.QR_ENROLLMENT_TYPE_WITHOUT_LDAP
    switch (qrEnroll) {
        case Constant.BRING_YOUR_OWN_DEVICE:
            return serverUrl = serverUrl + ServerConstants.BYOD_DEVICE
        case Constant.FULLY_MANAGED:
            return serverUrl = serverUrl + ServerConstants.FULLY_MANAGED
        case Constant.DEDICATED:
            return serverUrl = serverUrl + ServerConstants.DEDICATED_DEVICE
        case Constant.COPE:
            return serverUrl = serverUrl + ServerConstants.COPE_DEVICE
        default:
            return serverUrl
    }
}

export function getAppleTooltipList(t) {
    return [
        {
            child: [
                {
                    name: t('lockDevice'),
                    value: 'll-1-1'
                },
                {
                    name: t('applyInstitutionalSettings'),
                    value: 'll-1-2'
                },
                {
                    name: t('installAndRemoveInstitutionalData'),
                    value: 'll-1-3'
                },
                {
                    name: t('installAndRemoveInstitutionalApps'),
                    value: 'll-1-4'
                }
            ],
            name: t('forPersonallyOwnedDevicesItCan'),
            value: 'll-1'
        },
        {
            child: [
                {
                    name: t('wipeDataAndSettingsFromDevice'),
                    value: 'll-2-1'
                },
                {
                    name: t('trackLocationOfYourDevice'),
                    value: 'll-2-2'
                },
                {
                    name: t('removeAnythingTheyDidNotInstall'),
                    value: 'll-2-3'
                },
                {
                    name: t('addRemoveConfigurationsFiles'),
                    value: 'll-2-4'
                },
                {
                    name: t('addRemoveProvisioningFiles'),
                    value: 'll-2-5'
                }
            ],
            name: t('forPersonallyOwnedDevicesItCannot'),
            value: 'll-2'
        }
    ]
}

export function getEnrollmentTypeList(t) {
    var items = [
        {
            id: Constant.BRING_YOUR_OWN_DEVICE,
            text: t('bringYourOwnDevice')
        },
        {
            id: Constant.FULLY_MANAGED,
            text: t('fullyManaged')
        },
        {
            id: Constant.DEDICATED,
            text: t('dedicated')
        }
    ]
    if (window.globalConfig.COPE_ENROLLMENT) {
        items = [...items, {
            id: Constant.COPE,
            text: t('fullyManagedDeviceWithAWorkProfile')
        }]
    }
    return items
}

export function getAndroidQREnrollTooltipList(t) {
    var items = [{
        child: [
        ],
        name: t('forAndroidDeviceOnly'),
        value: 'll-1'
    }]

    var child = [
        {
            name: t('bringYourOwnDevice'),
            value: 'll-1-1'
        },
        {
            name: t('fullyManaged'),
            value: 'll-1-2'
        },
        {
            name: t('dedicated'),
            value: 'll-1-3'
        }
    ]
    if (window.globalConfig.COPE_ENROLLMENT) {
        child = [...child, {
            name: t('fullyManagedDeviceWithAWorkProfile'),
            value: 'll-1-4'
        }]
    }
    items[0].child = child
    return items
}