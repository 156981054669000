import '@patron/patron-css/patron/index.css'
import React, { useState } from "react"
import { Tooltip } from "@patron/patron-react/tooltip"
import { Heading } from "@patron/patron-react/heading"
import { Label } from "@patron/patron-react/label"
import { useHistory } from 'react-router-dom'
import { Notification } from "@patron/patron-react/notification"
import { useTranslation } from 'react-i18next'
import { Button } from "@patron/patron-react/button"
import base64 from 'react-native-base64'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import "./ManageToken.scss"
import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as emailConst from '../../constants/EmailConstants'
import * as constants from '../../constants/Constants'
import * as emailUtil from '../../util/EmailUtility'
import * as error from '../../constants/ErrorCodes'
import * as cacheUtil from '../../util/CacheUtility'
import * as ErrorUtil from '../../util/ErrorUtility'

function ManageToken() {
    const { t } = useTranslation()
    const [uploadFile, setUploadFile] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const history = useHistory()

    function getBase64(file, cb) {
        let reader = new FileReader()
        reader.onload = function () {
            cb(reader.result)
        }
        reader.onerror = function () {
            defaultError()
        }
        reader.readAsDataURL(file)
    }

    function invokeUploadTokenAPI(base64File) {
        fetch(window.location.origin + serverConstants.ADMIN_COMMAND_URL, {
            "method": "POST",
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
            "body": JSON.stringify({
                path_param: "token",
                payload: base64File,
                target: "mdm"
            })
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) handleUnauthorizedResponse()
                else if (response.status === error.HTTP_BAD_REQUEST) uploadTokenBadRequestError(response)
                else if (!response.ok) {
                    setShowSpinner(false)
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                }
                else return response.json()
            })
            .then((response) => {
                if (response) {
                    setShowSpinner(false)
                    setShowSuccess(true)
                }
            })
            .catch((error) => {
                defaultError(error)
            });
    }

    function invokeRequestTokenAPI() {
        setNotificationsFalse()
        fetch(window.location.origin + serverConstants.ADMIN_COMMAND_URL, {
            "method": "POST",
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
            "body": JSON.stringify({
                path_param: "secret",
                target: "mdm"
            })
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) handleUnauthorizedResponse()
                else if (!response.ok) {
                    setShowSpinner(false)
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    processRequestTokenAPIResponse(response)
                    setShowSpinner(false)
                }
            })
            .catch((error) => {
                defaultError(error)
            });
    }

    function handleUnauthorizedResponse() {
        setShowSpinner(false)
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        history.push('/config?session=false')
        window.location.reload()
    }

    function defaultError(error) {
        setShowSpinner(false)
        setShowError(true)
        setErrorMsg(error)
    }

    function uploadTokenBadRequestError(response) {
        setShowSpinner(false)
        setShowError(true)
        response.json().then(response => {
            var errorMsg = t('invalidOrExpiredToken')
            if (constants.INSUFFICIENT_PRIVILEGES === response.error) errorMsg = t('insufficientPrivileges')
            setErrorMsg(error.HTTP_BAD_REQUEST + ": " + errorMsg)
        })
        return false
    }

    function processRequestTokenAPIResponse(response) {
        let responseData = Buffer.from(response.response, "base64").toString();
        responseData = JSON.parse(responseData);
        var hostOrSerialNumber = window.globalConfig.CLOUD_DEPLOYMENT ? window.location.origin : responseData.serialNo
        var body = emailConst.TOKEN_EMAIL_BODY(base64.encode(responseData.secret), hostOrSerialNumber)
        window.location = emailUtil.Email(responseData.emailRecipient, emailConst.TOKEN_EMAIL_SUBJECT, body)
    }

    function setNotificationsFalse() {
        setShowError(false)
        setShowSuccess(false)
    }

    function showFileWarning() {
        setShowSpinner(false)
        setShowError(true)
        setErrorMsg(t('uploadValidTokenFile'))
    }

    return (<React.Fragment>
        {showSpinner && <Spinner />}
        <div className="hcl-container">
            <Notification
                onClose={() => { setShowError(false) }}
                title={errorMsg}
                type="danger"
                visible={showError}
            />
            <Notification
                onClose={() => setShowSuccess(false)}
                title={t('uploadTokenMsg')}
                type="success"
                visible={showSuccess}
            />
            <div className="mt-4">
                <Heading type="h4" >
                    {t('manageToken')}
                </Heading>
            </div>
            <div className="mt-5">
                <FontAwesomeIcon icon={faArrowRight} />&nbsp;
                <Label>
                    {t('tokenEnablesReg')}
                </Label>
            </div>
            <div className="mt-2">
                <FontAwesomeIcon icon={faArrowRight} />&nbsp;
                <Label>
                    {t('clickRequestTokenToInitiate')}
                </Label>
            </div>
            <div className="mt-2">
                <FontAwesomeIcon icon={faArrowRight} />&nbsp;
                <Label>
                    {t('clickUploadToken')}
                </Label>
            </div>
            <div className="mt-2">
                <FontAwesomeIcon icon={faArrowRight} />&nbsp;
                <Label>
                    {t('tokensAreOneTime')}
                </Label>
            </div>

            <div className="hcl-row">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Tooltip content={t('initiateTokenRequest')} type="icon">
                    <Button
                        type="primary"
                        onClick={(e) => {
                            invokeRequestTokenAPI()
                            e.preventDefault();
                        }}>
                        {t('requestToken')}
                    </Button>
                </Tooltip> &nbsp; &nbsp;
                <Tooltip content={t('uploadTokenToRegOrUnReg')} type="icon">
                    <Button
                        type="primary"
                        disabled={showSpinner}
                        onClick={() => { uploadFile.click() }}>
                        {t('uploadToken')}
                        <input id="uploadToken"
                            type="file"
                            ref={(ref) => setUploadFile(ref)}
                            style={{ display: 'none' }}
                            onInput={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                setNotificationsFalse()
                                setShowSpinner(true)
                                var file = e.target.files[0]
                                if (file) {
                                    var fileExt = file.name.split('.').pop()
                                    if (fileExt !== "enc") {
                                        showFileWarning()
                                    } else {
                                        var base64File = '';
                                        getBase64(file, (result) => {
                                            base64File = result;
                                            invokeUploadTokenAPI(base64File)
                                        })
                                    }
                                }
                                e.target.value = null
                            }}
                        />
                    </Button>
                </Tooltip>
            </div>
        </div>
    </React.Fragment>)
}

export default ManageToken