import '@patron/patron-css/patron/index.css'
import React, { useState, useEffect } from "react"
import { Button } from "@patron/patron-react/button"
import { Paragraph } from "@patron/patron-react/paragraph"
import { Checkbox } from "@patron/patron-react/checkbox"
import { Notification } from "@patron/patron-react/notification"
import { Dropdown } from "@patron/patron-react/dropdown"
import { Tooltip } from "@patron/patron-react/tooltip"
import { List } from "@patron/patron-react/list"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom"

import "./EnrollWithOutLdap.scss"
import CompanyHeader from "../../companyheader/CompanyHeader"
import * as ServerConstants from '../../constants/ServerConstants'
import * as Constant from '../../constants/Constants'
import * as EnrollUtil from '../../util/EnrollUtility'
import * as rebrandingUtil from '../../util/RebrandingUtility'
import QRModal from '../../qr-modal/QRModal'

function EnrollWithOutLdap() {

    const { t } = useTranslation()
    const [qrEnroll, setQrEnroll] = useState("")
    const [disableEnrollButton, setDisableEnrollButton] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showQRModal, setQRModal] = useState(false)
    const [qrImg, setQRImg] = useState("")
    const [androidQREnrollCheck, setAndroidQREnrollCheck] = useState(false)

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()

    useEffect(() => {
        rebrandingUtil.setBrowserTab()
    }, [])

    const updateQrEnrollOption = (e) => {
        if (e) {
            setQrEnroll(e)
        }
    }

    const enroll = () => {
        setDisableEnrollButton(true)
        setAndroidQREnrollCheck(false)
        if (EnrollUtil.IsQREnroll(qrEnroll)) {
            apiQrEnroll()
        } else {
            apiEnroll()
        }
    }

    function apiEnroll() {
        let dest = new URL(EnrollUtil.getServerURL(), window.location)
        if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_WIN) {
            winEnroll()
        } else if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) {
            window.location = dest.href
        } else if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_ANDROID) {
            enrollAndroidDevice(dest.href)
        }
    }

    function enrollAndroidDevice(url) {
        fetch(url)
            .then((response) => {
                setDisableEnrollButton(false)
                return response.text()
            })
            .then((imageUrl) => {
                window.location = imageUrl
            })
            .catch(() => {
                enrollErrorResponse()
            })
    }

    function enrollErrorResponse() {
        setDisableEnrollButton(false)
        setShowError(true)
        setErrorMsg(t('unableToEnroll'))
    }

    function winEnroll() {
        let dest = new URL(EnrollUtil.getWinServerURL(), window.location)
        window.location = dest.href
        setDisableEnrollButton(false)
        return false
    }

    function apiQrEnroll() {
        let serverUrl = EnrollUtil.getQREnrollURlWithoutLDAP(qrEnroll)
        fetch(serverUrl)
            .then((response) => {
                disableEnrollBasedOnOS()
                if (!response.ok) {
                    enrollErrorResponse()
                    return false
                }
                else return response.text()
            })
            .then(response => {
                if (response) {
                    downloadQRImage(response)
                }
            })
            .catch(() => {
                disableEnrollBasedOnOS()
                enrollErrorResponse()
            })
    }

    function disableEnrollBasedOnOS() {
        if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_WIN) {
            setTimeout(() => {
                disableEnroll()
            }, 4000)
        } else {
            setQrEnroll("")
            window.setTimeout(disableEnroll, 4000)
        }
    }

    function disableEnroll() {
        setQrEnroll("")
        setDisableEnrollButton(false)
    }

    const onQREnrollChange = () => {
        setAndroidQREnrollCheck(!androidQREnrollCheck)
        if (androidQREnrollCheck) {
            setQrEnroll('')
        }
    }

    const downloadQRImage = async (imageUrl) => {
        const res = await fetch(imageUrl)
        const imageBlob = await res.blob()
        const imageObjectURL = URL.createObjectURL(imageBlob)
        setQRImg(imageObjectURL)
        setQRModal(true)
    }
    
    const setModalClose = () => {
        setQRModal(false)
        setQRImg("")
    }

    return (
        <React.Fragment>
            <CompanyHeader />
            <section className="hcl-container hcl-flex p-0 enroll-container" style={rebrandingUtil.rebrandingUIBackgroundStyle()}>
                <div>
                    <div className="enroll-wrapper" style={{ background: rebrandingUtil.setBrandLogoPanelBg() }}>
                        <div className="hcl-text-center">
                            <img
                                src={rebrandingUtil.setBrandLogo()}
                                alt="product logo"
                            />
                        </div>
                        <Notification
                            onClose={() => { setShowError(false) }}
                            title={errorMsg}
                            type="danger"
                            visible={showError}
                        />
                        <form className="p-5">
                            {query.get('enrollType') !== Constant.ENROLL_TYPE_ZTD && <div>
                               <div className='hcl-row mt-2'>
                                    <div className='hcl-col-11 hcl-flex mb-2 enroll-options'>
                                        <Checkbox
                                            id="androidQREnrollCheck"
                                            label={t('enrollUsingQRCode')}
                                            checked={androidQREnrollCheck}
                                            onChange={() => onQREnrollChange()}
                                        /><span className="hcl-type-legal android-only">{t('forAndroidDevicesOnly')}</span>
                                    </div>
                                    <div className='hcl-col-1'>
                                        <Tooltip type="interactive" className='tooltip-style' content={<div>
                                            <List
                                                listItems={EnrollUtil.getAndroidQREnrollTooltipList(t)}
                                            />
                                        </div>}>
                                            <svg
                                                className="hcl-interactive-tooltip mt-2"
                                                focusable="false"
                                                height="16"
                                                preserveAspectRatio="xMidYMid meet"
                                                viewBox="0 0 16 16"
                                                width="16"
                                                xmlns="https://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.5 11V6.5h-2v1h1V11H6v1h4v-1zM8 3.5c-.4 0-.8.3-.8.8s.4.7.8.7.8-.3.8-.8-.4-.7-.8-.7z" />
                                                <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                                            </svg>
                                        </Tooltip>
                                    </div>
                                </div>
                                {androidQREnrollCheck &&
                                    <Dropdown
                                        items={EnrollUtil.getEnrollmentTypeList(t)}
                                        selectedItem={qrEnroll}
                                        onChange={(e) => {
                                            setQrEnroll(e.id)
                                        }}
                                        label={t('qREnrollmentType')}
                                        type="bottom"
                                    />}
                            </div>}
                            <div className="hcl-text-center mt-5">
                                <Button
                                    className="hcl-primary"
                                    style={{ background: rebrandingUtil.setButtonColor() }}
                                    disabled={disableEnrollButton|| (androidQREnrollCheck && qrEnroll === '') }
                                    onClick={enroll}
                                >
                                    {t('enroll')}
                                </Button>
                            </div>
                            <div className='mt-1 hcl-text-center'>
                                <Link
                                    to={{ pathname: ServerConstants.NEED_HELP_ENROLL_PAGE }}
                                    target="_blank"
                                    title={t('needHelpEnrolling')}
                                    rel="noopener noreferrer"
                                >
                                    {t('needHelpEnrolling')}
                                </Link>
                            </div>
                            <Paragraph className="hcl-type-legal mt-4 hcl-text-center">
                                &copy; {rebrandingUtil.setCopyrightMessage(t)}
                            </Paragraph>
                        </form>
                    </div>
                </div>
                {showQRModal && <QRModal qrUrl={qrImg} setModalClose={setModalClose.bind(this)} />}
            </section>
        </React.Fragment>
    )
}

export default EnrollWithOutLdap