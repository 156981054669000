export const BYOD_FULLY_MANAGED = "byodfullymanaged"

export const DEDICATED = "dedicated"

export const COPE = "cope"

export const PLATFORM_WIN = "win"

export const PLATFORM_APPLE = "apple"

export const PLATFORM_ANDROID = "android"

export const REMEMBER_ME = "rememberMe"

export const USER_NAME = "userName"

export const PASSWORD = "password"

export const TRUE = "true"

export const MAC = "mac"

export const IPHONE = "iphone"

export const IPAD = "ipad"

export const HTTP_POST = "POST"

export const HTTP_PATCH = "PATCH"

export const ADMIN_USER_NAME = "bigfixadmin"

export const JWT_COOKIE = "MCM_JWT_TOKEN"

export const IPV4_IPV6_HOST_NAME_EXPRESSION = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/

export const NON_GOOGLE_WORKSPACE_ACCOUNT = "Non Google Workspace Account"

export const ENROLL_REMEMBER_ME = "enrollRememberMe"

export const ENROLL_USER_NAME = "enrollUserName"

export const ENROLL_PASSWORD = "enrollPassword"

export const HCL_COPYRIGHT_YEAR = "2023"

export const PROVISIONING_ADMIN_EXTRAS_BUNDLE = "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"

export const EXTRA_ENROLLMENT_TOKEN = "com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"

export const AUTOMATIC_USER_AUTHENTICATION = "automaticEnableUserAuthentication"

export const INITIAL_DELAY = 6000

export const RETRY_DELAY = 3000

export const RETRY_COUNT = 10

// To make the responsive UI when user resize the window
export const MAX_LEFT_PANEL_SIZE = 1493

export const ENROLL_TYPE_PARAM = "?enrollType="

export const ENROLL_TYPE_ZTD = "ZTD"

export const INSUFFICIENT_PRIVILEGES = "Insufficient privileges"

export const SIGNIN_DETAILS = "signinDetails"

export const ENTERPRISE_DISPLAY_NAME = "enterpriseDisplayName"

export const CONTACT_INFO = "contactInfo"

export const BIGFIX = "BigFix"

export const FAVICON_BIGFIX_IMAGE_FILE = "/icon-150x150.png"

export const EMPTY = "EMPTY"

export const REBRANDING = "rebranding"

export const INSTITUTIONALLY_OWNED = "institutionallyOwned"

export const PERSONALLY_OWNED = "personallyOwned"

export const BRING_YOUR_OWN_DEVICE = "bringyourowndevice"

export const FULLY_MANAGED = "fullymanaged"

export const URL_REGEX = /^(http|https):\/\/[^ "]+$/