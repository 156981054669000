export function getSidebarItems(t) {
    var items = [
        {
            title: t('manageToken'),
            type: 'manageToken'
        },
        {
            title: t('enterpriseRegistration'),
            type: 'registerEnterprise'
        },
        {
            title: t('zeroTouchConfig'),
            type: 'zeroTouchEnterprise'
        },
        /*{
            title: t('privateAppsConfiguration'),
            type: 'privateAppsConfiguration'
        },*/
        {
            title: t('rebrandingTheUIScreen'),
            type: 'rebrandingUI'
        }
    ]

    /*if (!window.globalConfig.ENABLE_PRIVATE_APPS) {
        items = items.filter(item => item.type !== 'privateAppsConfiguration')
    }*/
    return items
}