import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { Heading } from "@patron/patron-react/heading"
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { Toast } from "@patron/patron-react/toast"

import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as cacheUtil from '../../util/CacheUtility'
import * as ErrorUtil from '../../util/ErrorUtility'
import * as webTokenUtil from '../../util/WebTokenUtil'
import * as error from '../../constants/ErrorCodes'

class PrivateAppsConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false,
            webToken: '',
            isAuthenticated: true,
            showError: false,
            errorMsg: ''
        }
    }

    componentDidMount() {
        this.getWebToken()
    }

    getWebToken() {
        this.setState({ showSpinner: true })
        var payload = {
            "parentFrameUrl": window.location.href,
            "enabledFeatures": [
                "PRIVATE_APPS"
            ]
        }
        webTokenUtil.getWebToken(payload)
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (response.status === error.HTTP_INTERNAL_SERVER_ERROR) this.handleInternalServerErrorResponse()
                else if (response.status === error.HTTP_FAILED_DEPENDENCY) ErrorUtil.handleFailedDependency(this, response)
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                this.processGetWebTokenAPIResponse(response)
            })
            .catch((error) => {
                this.setState({ showSpinner: false })
                this.setState({ showError: true })
                this.setState({ errorMsg: error })
            })
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    handleInternalServerErrorResponse() {
        this.setState({ showSpinner: false })
        this.setState({ showError: true })
        this.setState({ errorMsg: error.HTTP_INTERNAL_SERVER_ERROR + ": " + this.props.t('missingEnterpriseCredentials') })
        return false
    }

    processGetWebTokenAPIResponse(response) {
        if (response) {
            let responseData = Buffer.from(response.response, "base64").toString()
            responseData = JSON.parse(responseData)
            this.setState({ webToken: responseData.value })
            this.invokePrivateAppsIFrame()
        }
    }

    invokePrivateAppsIFrame() {
        var baseurl = serverConstants.IFRAME_PRIVATE_APPS_URL
        var generated_url = baseurl + this.state.webToken + serverConstants.IFRAME_PRIVATE_APPS_URL_PARAMETERS
        window.gapi.load('gapi.iframes', function () {
            var options = {
                'url': generated_url,
                'where': document.getElementById('iframe-container'),
                'attributes': { style: 'width: 100%; height:35rem', scrolling: 'yes' }
            }
            var iframe = window.gapi.iframes.getContext().openChild(options)
            iframe.register('onproductselect', function (event) {
                console.log(event)
            }, window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER)
        })
        this.setState({ showSpinner: false })
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {!this.state.isAuthenticated && <Redirect to='/config?session=false' />}
            {this.state.showError && <Toast
                iconDescription="close"
                className="toast-align"
                title={this.state.errorMsg}
                type="danger"
                visible
                closable
                onClose={() => { this.setState({ showError: false }) }}
            />}
            <div className="hcl-container">
                <div className="mt-4">
                    <Heading type="h4" >
                        {this.props.t('privateAppsConfiguration')}
                    </Heading>
                </div>
                <div id="iframe-container">

                </div>
                <div className="hcl-type-zeta mt-1 mb-2">
                    {this.props.t('pushPrivateAppsFromWebUINote')}
                </div>
            </div>
        </React.Fragment >)
    }
}

export default withTranslation()(PrivateAppsConfiguration)