/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

export const ADMIN_COMMAND_URL = '/admin/command'

export const ADMIN_LOGIN_URL = '/admin/login'

export const BFCONFIG_SID = "bfconfig.sid"

export const BFCONFIG_SIGNUP_URL_NAME = "bfconfig.signup_url_name"

export const ANDROID_COMMAND_URL = '/admin/command/internal'

export const IFRAME_BASE_URL = 'https://enterprise.google.com/android/zero-touch/embedded/companyhome?token='

export const IFRAME_APP_NAME = 'com.google.android.apps.work.clouddpc'

export const DEFAULT_POLICY = 'default_policy'

// valid 90 days(converted to seconds) which is max for enrollment token
export const DURATION_IN_SECONDS = '7776000s'

export const MANUAL_ZERO_TOUCH_URL = 'https://partner.android.com/zerotouch/u/1#a=1513956835&p=configurations-page'

export const MS_DEVICE_ENROLL = 'ms-device-enrollment:?mode=mdm&username=user@example.org&servername='

export const WIN_ENROLL = '/win/enroll'

export const ANDROID_ENROLL = '/android/enroll'

export const MDM_ENROLL = '/mdm/enroll'

export const ANDROID_QR_ENROLL_BASE_URL = '/android/qrenroll'

export const ANDROID_QR_ENROLL_DEDICATED_DEVICE = '/android/qrenroll?qrEnrollmentType=dedicatedDevice'

export const ANDROID_QR_ENROLL_COPE_DEVICE = '/android/qrenroll?qrEnrollmentType=copeDevice'

export const EMAIL_VALIDATE = '/emailvalidate?emailAddr='

export const MS_DEVICE_ENROLL_WITH_LDAP = 'ms-device-enrollment:?mode=mdm&username='

export const MS_SERVER_NAME_WITH_LDAP = '&servername='

export const MDM_LOGIN = '/login/'

export const QR_ENROLLMENT_TYPE = '&qrEnrollmentType='

export const QR_ENROLLMENT_TYPE_WITHOUT_LDAP = '?qrEnrollmentType='

export const BYOD_DEVICE = 'byodDevice'

export const FULLY_MANAGED = 'fullyManagedDevice'

export const DEDICATED_DEVICE = 'dedicatedDevice'

export const COPE_DEVICE = 'copeDevice'

export const EMAIL_ADDRESS = '?emailAddr='

export const ADMIN_CONFIG_URL = '/admin/configure'

export const LEARN_TO_GENERATE_WNS_CREDENTIALS = 'https://help.hcltechsw.com/bigfix/10.0/mcm/MCM/Config/c_WNS.html'

export const LEARN_TO_GENERATE_GOOGLE_CREDENTIALS = 'https://help.hcltechsw.com/bigfix/10.0/mcm/MCM/Config/c_enroll_to_managed_google_play_accounts_enterprise.html?hl=google%2Ccredential'

export const ANDROID_TERMS_AND_CONDITIONS = 'https://www.android.com/enterprise/terms/'

export const SERVICE_LOCATION_MAP = {
    kibana: {
        internal: false,
        link: "/app/kibana"
    },
    config: {
        internal: true,
        link: "/config/enterprise"
    },
    initconfig: {
        internal: true,
        link: "/initconfig/settings"
    },
    consul: {
        internal: false,
        link: "/app/consul"
    }
}

export const IFRAME_PRIVATE_APPS_URL = 'https://play.google.com/work/embedded/search?token='

export const IFRAME_PRIVATE_APPS_URL_PARAMETERS = '&mode=SELECT&showsearchbox=TRUE'

export const INIT_CONFIG_URL = '/initconfig'

export const CONFIG_URL = '/config'

export const ADMIN_REBRANDING_UI_URL = '/admin/rebranding'

export const NEED_HELP_ENROLL_PAGE = 'https://help.hcltechsw.com/bigfix/10.0/mcm/MCM/Config/bigfix_mcm_admin.html'

export const USER_ENROLL = '/mdm/userenroll'

export const AZURE_AUTH_URL = '/azure/auth'

export const REQUEST_TYPE_POST = 'POST'

export const SAML_AUTH_URL = '/saml/auth'