import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { TextInput } from "@patron/patron-react/textinput"
import { TextArea } from "@patron/patron-react/textarea"
import { Label } from "@patron/patron-react/label"
import { Password } from "@patron/patron-react/password"
import { Checkbox } from "@patron/patron-react/checkbox"
import { Button } from "@patron/patron-react/button"
import { Header } from "@patron/patron-react/header"
import { Heading } from "@patron/patron-react/heading"
import { FileUploader } from "@patron/patron-react/fileuploader"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { InPageNavigation } from "@patron/patron-react/inpagenavigation"
import { Redirect } from 'react-router'
import base64 from 'react-native-base64'
import { Toast } from "@patron/patron-react/toast"
import { Modal } from "@patron/patron-react/modal"
import { Link } from 'react-router-dom'

import "./Configure.scss"
import BigfixLogo from "../../../static/img/bigfix_logo_white.png"
import LogoutModal from '../../logout-modal/LogoutModal'
import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as constants from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as cacheUtil from '../../util/CacheUtility'
import * as ErrorUtil from '../../util/ErrorUtility'
import * as rebrandingUtil from '../../util/RebrandingUtility'

class Configure extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false,
            showModal: false,
            showError: false,
            errorMsg: '',
            successMsg: '',
            isAuthenticated: true,
            showConfigModal: false,
            successRedirect: false,
            showNonGSuiteModal: false,
            dmzRelayUserName: '',
            dmzRelaySecret: '',
            dmzRelayDeploymentPort: '',
            dmzRelayUploadFile: '',
            dmzRelayUploadFileName: '',
            organizationName: '',
            adminServerUserName: constants.ADMIN_USER_NAME,
            adminServerSecret: '',
            applePushSecret: '',
            applePushCertificate: '',
            applePushKey: '',
            applePushWelcomeMessage: '',
            enableLDAPAuth: false,
            ldapURL: '',
            ldapBaseDN: '',
            ldapBaseUser: '',
            ldapBaseDNSecret: '',
            googleCredentials: '',
            wnsCredentials: '',
            isMastHeadAvailable: false,
            isAppleCertAvailable: false,
            isAppleKeyAvailable: false,
            isGoogleCredentialsAvailable: false,
            isWnsCredentialsAvailable: false,
            validMastHeadFileExt: false,
            validDmzRelay: false,
            invalidGoogleCredentials: false,
            enterpriseDisplayName: '',
            counter: 0,
            serverTimeoutModal: false,
        }
    }

    componentDidMount() {
        this.viewConfiguration()
        rebrandingUtil.setBrowserTab()
    }

    setModalClose() {
        this.setState({ showModal: false })
        this.setState({ showConfigModal: false })
        this.setState({ showNonGSuiteModal: false })
    }

    getBase64(file, cb) {
        let reader = new FileReader()
        reader.onload = function () {
            cb(reader.result)
        }
        reader.onerror = function () {
            this.uploadTokenError()
        }
        reader.readAsDataURL(file)
    }

    getNavigationalListItems() {
        const itemLists = [
            {
                label: this.props.t('installParameters'),
                link: 'installParameters'
            },
            {
                label: this.props.t('adminUICredentials'),
                link: 'androidServerAdmin'
            },
            {
                label: this.props.t('applePushCertificateAndKeyContent'),
                link: 'applePushCertificate'
            },
            {
                label: this.props.t('ldapParameters'),
                link: 'ldapParameters'
            },
            {
                label: this.props.t('googleCredentials'),
                link: 'googleGSuiteCredentials'
            },
            {
                label: this.props.t('wnsCredentials'),
                link: 'wnsCredentials'
            }
        ]
        if (window.globalConfig.RELAY_SETTINGS) {
            const dmzRelayItem = [
                {
                    label: this.props.t('dmzRelay'),
                    link: 'dmzRelay'
                }
            ]
            return [...dmzRelayItem, ...itemLists]
        } else {
            return itemLists
        }
    }

    uploadTokenError() {
        this.setState({ showError: true })
        this.setState({ errorMsg: this.props.t('uploadTokenErrorMsg') })
    }

    configure() {
        this.setState({ showSpinner: true })
        this.setState({ showConfigModal: false })
        fetch(window.location.origin + serverConstants.ADMIN_CONFIG_URL, {
            "method": "POST",
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
            "body": JSON.stringify({
                path_param: "configurations",
                payload: base64.encode(JSON.stringify(this.state))
            })
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    setTimeout(() => {
                        this.invokeInitconfigPage()
                    }, constants.INITIAL_DELAY)
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    defaultError(error) {
        this.setState({ showSpinner: false })
        this.setState({ showError: true })
        this.setState({ errorMsg: error })
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    invokeInitconfigPage() {
        fetch(window.location.origin + serverConstants.INIT_CONFIG_URL, {
            "method": "GET",
        }).then((response) => {
            if (!response.ok) {
                setTimeout(() => {
                    if (this.state.counter < constants.RETRY_COUNT) {
                        this.invokeInitconfigPage()
                        this.setState({ counter: this.state.counter + 1 })
                    }
                    else {
                        this.setState({ showSpinner: false })
                        this.setState({ serverTimeoutModal: true })
                    }
                }, constants.RETRY_DELAY)
            }
            else this.invokeInitconfigElse()
        })
    }

    invokeInitconfigElse() {
        this.setState({ serverTimeoutModal: false })
        this.setState({ showSpinner: false })
        this.setState({ successRedirect: true })
        cacheUtil.clearCookie(constants.JWT_COOKIE)
    }

    viewConfiguration() {
        this.setState({ showSpinner: true })
        fetch(window.location.origin + serverConstants.ADMIN_CONFIG_URL, {
            "method": "GET",
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    let responseData = Buffer.from(response.response, "base64").toString();
                    responseData = JSON.parse(responseData)
                    this.loadConfigData(responseData)
                    this.setState({ showSpinner: false })
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    loadConfigData(response) {
        this.setState({ dmzRelayUserName: response.configuration.dmzRelayUserName })
        this.setState({ dmzRelaySecret: response.configuration.dmzRelaySecret })
        this.setState({ dmzRelayDeploymentPort: response.configuration.dmzRelayDeploymentPort })
        this.setState({ organizationName: response.configuration.organizationName })
        this.setState({ adminServerUserName: response.configuration.adminServerUserName })
        this.setState({ adminServerSecret: response.configuration.adminServerSecret })
        this.setState({ applePushSecret: response.configuration.applePushSecret })
        this.setState({ applePushWelcomeMessage: response.configuration.applePushWelcomeMessage })
        this.setState({ enableLDAPAuth: response.configuration.enableLDAPAuth })
        this.setState({ ldapURL: response.configuration.ldapURL })
        this.setState({ ldapBaseDN: response.configuration.ldapBaseDN })
        this.setState({ ldapBaseUser: response.configuration.ldapBaseUser })
        this.setState({ ldapBaseDNSecret: response.configuration.ldapBaseDNSecret })
        if (response.configuration.dmzRelayUploadFile === "true") {
            this.setState({ isMastHeadAvailable: true })
        }
        if (response.configuration.applePushCertificate === "true") {
            this.setState({ isAppleCertAvailable: true })
        }
        if (response.configuration.applePushKey === "true") {
            this.setState({ isAppleKeyAvailable: true })
        }
        if (response.configuration.googleCredentials === "true") {
            this.setState({ isGoogleCredentialsAvailable: true })
        }
        if (response.configuration.wnsCredentials === "true") {
            this.setState({ isWnsCredentialsAvailable: true })
        }
    }

    deleteGoogleCredentials() {
        this.setState({ showSpinner: true })
        this.setState({ showNonGSuiteModal: false })
        fetch(window.location.origin + serverConstants.ADMIN_CONFIG_URL, {
            "method": "DELETE",
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    this.setState({ isGoogleCredentialsAvailable: false })
                    window.open("/config/enterprise", "_blank")
                    this.setState({ showSpinner: false })
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    validateLdapParameters() {
        if (this.state.enableLDAPAuth && (this.state.ldapURL === undefined || this.state.ldapURL === ''
            || this.state.ldapBaseDN === undefined || this.state.ldapBaseDN === ''
            || this.state.ldapBaseUser === undefined || this.state.ldapBaseUser === ''
            || this.state.ldapBaseDNSecret === undefined || this.state.ldapBaseDNSecret === '')) {
            return false
        }
        return true
    }

    readGoogleCredentialFile(file) {
        const fileReader = new FileReader()
        fileReader.readAsText(file, "UTF-8")
        fileReader.onload = e => {
            let value = e.target.result.toString()
            try {
                let jsonValue = JSON.parse(value)
                if (jsonValue !== undefined && jsonValue.enterprise_id !== undefined && jsonValue.enterprise_id !== "") {
                    this.setState({ enterpriseDisplayName: jsonValue.enterpriseDisplayName ? jsonValue.enterpriseDisplayName : '' })
                    this.getBase64(file, (result) => {
                        this.setState({ googleCredentials: result })
                    })
                } else {
                    this.setState({ invalidGoogleCredentials: true })
                }
            } catch (e) {
                this.setState({ invalidGoogleCredentials: true })
            }
        }
    }

    disableConfigureButton() {
        if (window.globalConfig.RELAY_SETTINGS) {
            return this.state.showSpinner || this.state.adminServerSecret === undefined || this.state.adminServerSecret === ''
            || !this.validateLdapParameters() || this.state.validMastHeadFileExt || this.state.validDmzRelay || this.state.invalidGoogleCredentials
            || this.state.dmzRelaySecret === undefined || this.state.dmzRelaySecret === ''
            || this.state.dmzRelayUserName === undefined || this.state.dmzRelayUserName === ''
            || (!this.state.isMastHeadAvailable && (this.state.dmzRelayUploadFile === undefined || this.state.dmzRelayUploadFile === ''))
        }
        return this.state.showSpinner || this.state.adminServerSecret === undefined || this.state.adminServerSecret === ''
            || !this.validateLdapParameters() || this.state.validMastHeadFileExt || this.state.invalidGoogleCredentials
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {this.state.showConfigModal && <Modal
                actions={[
                    {
                        label: this.props.t('okButton'),
                        type: "primary",
                        handler: () => this.configure()
                    },
                    {
                        label: this.props.t('cancel'),
                        type: 'secondary',
                        handler: () => this.setModalClose()
                    }
                ]}
                heading={this.props.t('configure')}
                onClose={() => { this.setModalClose() }}
                type="warning"
            >
                <p>
                    {this.props.t('configurationModalDesc')}
                </p>
            </Modal>}
            {this.state.showNonGSuiteModal && <Modal
                actions={[
                    {
                        label: this.props.t('yes'),
                        type: "primary",
                        handler: () => this.deleteGoogleCredentials()
                    },
                    {
                        label: this.props.t('no'),
                        type: 'secondary',
                        handler: () => this.setModalClose()
                    }
                ]}
                heading={this.props.t('registerANewEnterprise')}
                onClose={() => { this.setModalClose() }}
                type="warning"
            >
                <p>
                    {this.props.t('nonGSuiteTypeModalDesc')}
                </p>
            </Modal>}
            {this.state.serverTimeoutModal && <Modal
                actions={[
                    {
                        label: this.props.t('okButton'),
                        type: "primary",
                        handler: () => this.invokeInitconfigElse()
                    }
                ]}
                heading={this.props.t('serverTimedOut')}
                onClose={() => { this.invokeInitconfigElse() }}
                type="warning"
            >
                <p>
                    {this.props.t('serverTimedOutModalDesc')}
                </p>
            </Modal>}
            {this.state.showModal && <LogoutModal state={this.state} logoutUrl='/initconfig' setModalClose={this.setModalClose.bind(this)} />}
            {!this.state.isAuthenticated && <Redirect to='/initconfig?session=false' />}
            {this.state.successRedirect && <Redirect to='/initconfig' />}
            {this.state.showError && <Toast
                iconDescription="close"
                className="toast-align"
                title={this.state.errorMsg}
                type="danger"
                visible
                closable
                onClose={() => { this.setState({ showError: false }) }}
            />}
            <Header
                icons={[
                    {
                        icon: <FontAwesomeIcon icon={faSignOutAlt} onClick={() => this.setState({ showModal: true })} />
                    }
                ]}
                logo={<img alt="Logo" className="bigfix-logo" src={rebrandingUtil.isCompanyLogoImageConfigured() ?rebrandingUtil.getCompanyLogoImage() : BigfixLogo} />}
                style={{ background: rebrandingUtil.setCompanyLogoBg() }}
            />
            <div className={`hcl-container p-0 parameters-container`}>
                <div className="page-heading">
                    <Heading type="h4" >
                        {this.props.t('configure')}
                    </Heading>
                </div>
                <div className='hcl-row parameters-wrapper'>
                    <div className='hcl-col-3 mt-2'>
                        <InPageNavigation
                            listItems={this.getNavigationalListItems()}
                        />
                    </div>
                    <div className='hcl-col-9 mt-2 parameters-settings'>
                        <form>
                            {window.globalConfig.RELAY_SETTINGS && <div id="dmzRelay">
                                <section className="parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('dmzRelay')}</Label>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                id="dmzRelay"
                                                aria-label="text input"
                                                data-invalid={this.state.validDmzRelay || this.state.dmzRelayUserName === undefined || this.state.dmzRelayUserName === ''}
                                                value={this.state.dmzRelayUserName}
                                                onChange={(e) => {
                                                    this.setState({ validDmzRelay: false })
                                                    const value = e.currentTarget.value
                                                    if (constants.IPV4_IPV6_HOST_NAME_EXPRESSION.test(value)) {
                                                        this.setState({ dmzRelayUserName: value })
                                                    } else {
                                                        this.setState({ validDmzRelay: true })
                                                    }

                                                }}
                                                required
                                            />
                                            <Label htmlFor="dmzRelay">{this.props.t('dmzRelay')}<span className="span-style">*</span></Label>
                                            {this.state.validDmzRelay &&
                                                <div className="hcl-error-msg">
                                                    {this.props.t('notAValidIPAddressOrHostname')}
                                                </div>
                                            }
                                        </div>
                                        <div className="mt-2 hcl-col-4 hcl-form-group">
                                            <Password
                                                id="dmzRelaySecret"
                                                aria-label="text input"
                                                data-invalid={this.state.dmzRelaySecret === undefined || this.state.dmzRelaySecret === ''}
                                                value={this.state.dmzRelaySecret}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ dmzRelaySecret: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="dmzRelaySecret">{this.props.t('secureRegistrationPassPhrase')}<span className="span-style">*</span></Label>
                                        </div>
                                    </div>
                                    <div className="hcl-row">
                                        <div className="ml-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                type="text"
                                                id="dmzRelayDeploymentPort"
                                                value={this.state.dmzRelayDeploymentPort}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ dmzRelayDeploymentPort: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="dmzRelayDeploymentPort">{this.props.t('deploymentPort')}</Label>
                                            <div className="hcl-helper-text">
                                                {this.props.t('optionalDefault')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-2">
                                        <FileUploader
                                            className="hcl-btn hcl-primary hcl-sm"
                                            id="mastheadFile"
                                            label={this.props.t('mastheadFile')}
                                            required
                                            onChange={(files) => {
                                                this.setState({ validMastHeadFileExt: false })
                                                var file = files[0]
                                                if (file) {
                                                    this.setState({ isMastHeadAvailable: false })
                                                    var fileExt = file.name.split('.').pop()
                                                    if (fileExt !== "txt" && fileExt !== "afxm" && fileExt !== "efxm") {
                                                        this.setState({ validMastHeadFileExt: true })
                                                    } else {
                                                        this.setState({ dmzRelayUploadFileName: file.name })
                                                        this.getBase64(file, (result) => {
                                                            this.setState({ dmzRelayUploadFile: result })
                                                        })
                                                    }
                                                }
                                            }}
                                        >
                                            {this.props.t('uploadFile')}
                                        </FileUploader>
                                        {(!this.state.isMastHeadAvailable && !this.state.validMastHeadFileExt && (this.state.dmzRelayUploadFile === undefined || this.state.dmzRelayUploadFile === '')) &&
                                            <div className="hcl-helper-text ext-label-style">
                                                {this.props.t('pleaseUploadMastheadFile')}
                                            </div>}
                                        {this.state.isMastHeadAvailable &&
                                            <Label className="label-style">
                                                {this.props.t('mastHeadFileName')}
                                            </Label>
                                        }
                                        {this.state.validMastHeadFileExt &&
                                            <Label className="ext-label-style">
                                                {this.props.t('fileExtensionNotAllowed')}
                                            </Label>
                                        }
                                    </div>
                                </section>
                            </div>}
                            <div id="installParameters">
                                <section className="mt-2 parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('installParameters')}</Label>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                type="text"
                                                placeholder="Bigfix Organization"
                                                id="organizationName"
                                                value={this.state.organizationName}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ organizationName: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="organizationName">{this.props.t('organizationName')}</Label>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div id="androidServerAdmin">
                                <section className="mt-2 parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('adminUICredentials')}</Label>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                type="text"
                                                id="adminServerUserName"
                                                value={this.state.adminServerUserName}
                                                readOnly
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ adminServerUserName: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="adminServerUserName">{this.props.t('username')}</Label>
                                        </div>
                                        <div className="mt-2 hcl-col-4 hcl-form-group">
                                            <Password
                                                id="adminServerSecret"
                                                aria-label="text input"
                                                data-invalid={this.state.adminServerSecret === undefined || this.state.adminServerSecret === ''}
                                                value={this.state.adminServerSecret}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ adminServerSecret: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="adminServerSecret">{this.props.t('password')}<span className="span-style">*</span></Label>
                                            {(this.state.adminServerSecret === undefined || this.state.adminServerSecret === '') &&
                                                <div className="hcl-error-msg">
                                                    {this.props.t('enterAPassword')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div id="applePushCertificate">
                                <section className="mt-2 parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('applePushCertificateAndKeyContent')}</Label>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <Password
                                                id="applePushSecret"
                                                value={this.state.applePushSecret}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ applePushSecret: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="applePushSecret">{this.props.t('applePushCertificatePassword')}</Label>
                                        </div>
                                    </div>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <FileUploader
                                                className="hcl-btn hcl-primary hcl-sm"
                                                id="applePushCertificateFile"
                                                label={this.props.t('applePushCertificate')}
                                                onChange={(files) => {
                                                    var file = files[0]
                                                    if (file) {
                                                        this.setState({ isAppleCertAvailable: false })
                                                        this.getBase64(file, (result) => {
                                                            this.setState({ applePushCertificate: result })
                                                        });
                                                    }
                                                }}
                                            >
                                                {this.props.t('uploadFile')}
                                            </FileUploader>
                                            {this.state.isAppleCertAvailable &&
                                                <Label className="label-style">
                                                    {this.props.t('applePushCertificateFileName')}
                                                </Label>
                                            }
                                        </div>
                                        <div className="mt-2 hcl-col-4 hcl-form-group">
                                            <FileUploader
                                                className="hcl-btn hcl-primary hcl-sm"
                                                id="applePushKey"
                                                label={this.props.t('applePushKey')}
                                                onChange={(files) => {
                                                    var file = files[0]
                                                    if (file) {
                                                        this.setState({ isAppleKeyAvailable: false })
                                                        this.getBase64(file, (result) => {
                                                            this.setState({ applePushKey: result })
                                                        });
                                                    }
                                                }}
                                            >
                                                {this.props.t('uploadFile')}
                                            </FileUploader>
                                            {this.state.isAppleKeyAvailable &&
                                                <Label className="label-style">
                                                    {this.props.t('applePushKeyFileName')}
                                                </Label>
                                            }
                                        </div>
                                    </div>
                                    <section className="ml-3 mb-2 parameters-inner-section">
                                        <Label className="mt-2 ml-1">{this.props.t('userAgreementForMacMDMEnrollment')}</Label>
                                        <div className="hcl-row">
                                            <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                                <TextArea
                                                    id="applePushWelcomeMessage"
                                                    aria-label="comments"
                                                    placeholder="Welcome to BigFix MDM on SOFY"
                                                    value={this.state.applePushWelcomeMessage}
                                                    onChange={(e) => {
                                                        const value = e.currentTarget.value
                                                        this.setState({ applePushWelcomeMessage: value })
                                                    }}
                                                />
                                                <Label htmlFor="applePushWelcomeMessage">{this.props.t('welcomeMessage')}</Label>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                            <div id="ldapParameters">
                                <section className="mt-2 parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('ldapParameters')}</Label>
                                    <div className="ml-2">
                                        <Checkbox
                                            id="enableldap"
                                            label={this.props.t('enableLdapAuth')}
                                            checked={this.state.enableLDAPAuth}
                                            onChange={() => {
                                                this.setState({ enableLDAPAuth: !this.state.enableLDAPAuth })
                                                if (this.state.enableLDAPAuth) {
                                                    this.setState({ ldapURL: '' })
                                                    this.setState({ ldapBaseDN: '' })
                                                    this.setState({ ldapBaseUser: '' })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                id="ldapURL"
                                                aria-label="text input"
                                                data-invalid={this.state.enableLDAPAuth && (this.state.ldapURL === undefined || this.state.ldapURL === '')}
                                                placeholder="ldap://<server>:<port>"
                                                value={this.state.ldapURL}
                                                disabled={!this.state.enableLDAPAuth}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ ldapURL: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="ldapURL">{this.props.t('ldapURL')}{this.state.enableLDAPAuth && <span className="span-style">*</span>}</Label>
                                        </div>
                                        <div className="mt-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                id="ldapBaseDN"
                                                aria-label="text input"
                                                data-invalid={this.state.enableLDAPAuth && (this.state.ldapBaseDN === undefined || this.state.ldapBaseDN === '')}
                                                placeholder="dc=bigfixnw,dc=local"
                                                value={this.state.ldapBaseDN}
                                                disabled={!this.state.enableLDAPAuth}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ ldapBaseDN: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="ldapBaseDN">{this.props.t('ldapBaseDN')}{this.state.enableLDAPAuth && <span className="span-style">*</span>}</Label>
                                        </div>
                                    </div>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <TextInput
                                                id="ldapBaseUser"
                                                aria-label="text input"
                                                data-invalid={this.state.enableLDAPAuth && (this.state.ldapBaseUser === undefined || this.state.ldapBaseUser === '')}
                                                value={this.state.ldapBaseUser}
                                                disabled={!this.state.enableLDAPAuth}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ ldapBaseUser: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="ldapBaseUser">{this.props.t('ldapBaseUser')}{this.state.enableLDAPAuth && <span className="span-style">*</span>}</Label>
                                        </div>
                                        <div className="mt-2 hcl-col-4 hcl-form-group">
                                            <Password
                                                id="ldapBaseDNSecret"
                                                aria-label="text input"
                                                data-invalid={this.state.enableLDAPAuth && (this.state.ldapBaseDNSecret === undefined || this.state.ldapBaseDNSecret === '')}
                                                value={this.state.ldapBaseDNSecret}
                                                disabled={!this.state.enableLDAPAuth}
                                                onChange={(e) => {
                                                    const value = e.currentTarget.value
                                                    this.setState({ ldapBaseDNSecret: value })
                                                }}
                                                required
                                            />
                                            <Label htmlFor="ldapBaseDNSecret">{this.props.t('ldapBaseDNPassword')}{this.state.enableLDAPAuth && <span className="span-style">*</span>}</Label>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div id="googleGSuiteCredentials">
                                <section className="mt-2 parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('googleCredentials')}</Label>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <FileUploader
                                                className="hcl-btn hcl-primary hcl-sm"
                                                id="googleCredentials"
                                                label={this.props.t('googleGSuiteCredentials')}
                                                onChange={(files) => {
                                                    this.setState({ invalidGoogleCredentials: false })
                                                    var file = files[0]
                                                    if (file) {
                                                        this.setState({ isGoogleCredentialsAvailable: false })
                                                        this.readGoogleCredentialFile(file)
                                                    }
                                                }}
                                            >
                                                {this.props.t('uploadFile')}
                                            </FileUploader>
                                            {this.state.isGoogleCredentialsAvailable &&
                                                <Label className="label-style">
                                                    {this.props.t('googleCredentialsJson')}
                                                </Label>
                                            }
                                            {this.state.invalidGoogleCredentials &&
                                                <Label className="ext-label-style">
                                                    {this.props.t('validGoogleCredentialFile')}
                                                </Label>
                                            }
                                        </div>
                                    </div>
                                    <div className="hcl-row">
                                        <div className="ml-2 hcl-col-8 hcl-form-group">
                                            <Label>{this.props.t('nonGSuiteGoogleCredentialsLink')}
                                                &nbsp;<Link
                                                    to="#"
                                                    title={this.props.t('nonGSuiteGoogleCredentialsLink')}
                                                    rel="noopener noreferrer"
                                                    onClick={() => {
                                                        this.setState({ showNonGSuiteModal: true })
                                                    }}
                                                >
                                                    {this.props.t('clickHere')}
                                                </Link>
                                            </Label>
                                        </div>
                                    </div>
                                    <div className="hcl-row">
                                        <div className="ml-2 hcl-col-8 hcl-form-group">
                                            <Label>{this.props.t('learnToGenerateGoogleCredentials')}
                                                &nbsp;<Link
                                                    to={{ pathname: serverConstants.LEARN_TO_GENERATE_GOOGLE_CREDENTIALS }}
                                                    target="_blank"
                                                    title={this.props.t('learnToGenerateGoogleCredentials')}
                                                    rel="noopener noreferrer"
                                                >
                                                    {this.props.t('link')}
                                                </Link>
                                            </Label>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div id="wnsCredentials">
                                <section className="mt-2 parameters-section">
                                    <Label className="mt-2 ml-1">{this.props.t('wnsCredentials')}</Label>
                                    <div className="hcl-row">
                                        <div className="mt-2 ml-2 hcl-col-4 hcl-form-group">
                                            <FileUploader
                                                className="hcl-btn hcl-primary hcl-sm"
                                                id="wnsCredentialsFile"
                                                label={this.props.t('wnsCredentialsJson')}
                                                onChange={(files) => {
                                                    var file = files[0]
                                                    if (file) {
                                                        this.setState({ isWnsCredentialsAvailable: false })
                                                        this.getBase64(file, (result) => {
                                                            this.setState({ wnsCredentials: result })
                                                        });
                                                    }
                                                }}
                                            >
                                                {this.props.t('uploadFile')}
                                            </FileUploader>
                                            {this.state.isWnsCredentialsAvailable &&
                                                <Label className="label-style">
                                                    {this.props.t('wnsCredentialsJsonFile')}
                                                </Label>
                                            }
                                        </div>
                                    </div>
                                    <div className="hcl-row">
                                        <div className="ml-2 hcl-col-8 hcl-form-group">
                                            <Label>{this.props.t('learnToGenerateWnsCredentials')}
                                                &nbsp;<Link
                                                    to={{ pathname: serverConstants.LEARN_TO_GENERATE_WNS_CREDENTIALS }}
                                                    target="_blank"
                                                    title={this.props.t('learnToGenerateWnsCredentials')}
                                                    rel="noopener noreferrer"
                                                >
                                                    {this.props.t('link')}
                                                </Link>
                                            </Label>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="mt-1 hcl-type-zeta">
                                <span className="span-style">*</span> {this.props.t('mandatoryFields')}
                            </div>
                            <div className="hcl-row configure-button">
                                <div className="hcl-col-1 hcl-text-center mb-2">
                                    <Button
                                        className="hcl-primary"
                                        disabled={this.disableConfigureButton()}
                                        onClick={() => {
                                            this.setState({ showConfigModal: true })
                                        }}
                                    >
                                        {this.props.t('configure')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}

export default withTranslation()(Configure)
