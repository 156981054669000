import * as error from '../constants/ErrorCodes'

export function transactionErrorDetails(response, t) {
    let errorMsg
    if (response.status === error.HTTP_BAD_REQUEST) {
        errorMsg = error.HTTP_BAD_REQUEST + ": " + t('errorBadRequest')
    } else if (response.status === error.HTTP_UNAUTHORIZED) {
        errorMsg = error.HTTP_UNAUTHORIZED + ": " + t('errorUnauthorized')
    } else if (response.status === error.HTTP_FORBIDDEN) {
        errorMsg = error.HTTP_FORBIDDEN + ": " + t('errorForbidden')
    } else if (response.status === error.HTTP_NOT_FOUND) {
        errorMsg = error.HTTP_NOT_FOUND + ": " + t('errorNotFound')
    } else if (response.status === error.HTTP_LOCKED) {
        errorMsg = error.HTTP_LOCKED + ": " + t('loginAttemptsExceeded')
    } else if (response.status === error.HTTP_INTERNAL_SERVER_ERROR) {
        errorMsg = error.HTTP_INTERNAL_SERVER_ERROR + ": " + t('errorServerError')
    } else if (response.status === error.HTTP_BAD_GATEWAY) {
        errorMsg = error.HTTP_BAD_GATEWAY + ": " + t('errorBadGateway')
    } else if (response.status === error.HTTP_TOO_MANY_REQUESTS) {
        errorMsg = error.HTTP_TOO_MANY_REQUESTS + ": " + t('errorTooManyRequests')
    } else {
        errorMsg = t('errorDefault')
    }
    return errorMsg
}

export function handleFailedDependency(callerState, response) {
    getErrorMessageFromResponse(response)
    .then(errorMessage => {
        callerState.setState({ showSpinner: false })
        callerState.setState({ error: true })
        callerState.setState({ showError: true })
        callerState.setState({ errorMsg: errorMessage })
    });
    return false;
}

export async function getErrorMessageFromResponse(response) {
    const errorFromServer = await response.json().then(body => JSON.parse(body.error));
    return errorFromServer.CustomError + ": " + formatRawMessageFromGoogle(errorFromServer.RawErrorMsg);
}

function formatRawMessageFromGoogle(rawMessage) {
    if(rawMessage && rawMessage !== "") {
        const rawMessageSplitted = rawMessage.split(":")
        rawMessage = rawMessageSplitted.length > 1 ? rawMessageSplitted[1].replaceAll('"','') : rawMessageSplitted[0];
    }
    return rawMessage;
}