import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { Heading } from "@patron/patron-react/heading"
import { withTranslation } from 'react-i18next'
import { Radio } from '@patron/patron-react/radio'

import Manual from './Manual'
import Automatic from './Automatic'
import * as constants from '../../constants/Constants'

class ZeroTouchConfiguration extends Component {

    constructor(props) {
        super(props)
        this.state = {
            zeroTouchOption: 'manual',
        }
    }

    updateZeroTouchOption = (e) => {
        if (e) {
            this.setState({ zeroTouchOption: e })
        }
    }

    render() {
        return (<React.Fragment>
            <div className="hcl-container">
                <div className="mt-4">
                    <Heading type="h4" >
                        {this.props.t('zeroTouchConfig')}
                    </Heading>
                </div>
                <div className='hcl-row mt-1'>
                    <div className={this.props.windowSize < constants.MAX_LEFT_PANEL_SIZE ? 'hcl-col-3' : 'hcl-col-1'}>
                        <Radio
                            id='manual'
                            labelText={this.props.t('manual')}
                            name='zeroTouch'
                            value="manual"
                            checked={this.state.zeroTouchOption === "manual"}
                            onChange={this.updateZeroTouchOption}
                        />
                    </div>
                    <div className='hcl-col-2'>
                        <Radio
                            id='automatic'
                            labelText={this.props.t('automatic')}
                            name='zeroTouch'
                            value="automatic"
                            checked={this.state.zeroTouchOption === "automatic"}
                            onChange={this.updateZeroTouchOption}
                        />
                    </div>
                </div>
                <div>
                    {this.state.zeroTouchOption === 'manual' && <Manual />}
                    {this.state.zeroTouchOption === 'automatic' && <Automatic />}
                </div>
            </div>
        </React.Fragment >)
    }
}

export default withTranslation()(ZeroTouchConfiguration)