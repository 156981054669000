import '@patron/patron-css/patron/index.css'
import '@patron/patron-icon/dist/patron-font.css'
import React, { Component } from "react"
import { Header } from "@patron/patron-react/header"
import { Heading } from "@patron/patron-react/heading"
import { Sidebar } from '@patron/patron-react/sidebar'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { Toast } from "@patron/patron-react/toast"

import "./Main.scss"
import EnterpriseRegistration from './enterpriseregistration/EnterpriseRegistration'
import ManageToken from './managetoken/ManageToken'
import ZeroTouchConfiguration from './zerotouchconfiguration/ZeroTouchConfiguration'
import PrivateAppsConfiguration from './privateappsconfiguration/PrivateAppsConfiguration'
import RebrandingUI from './rebrandingui/RebrandingUI'
import BigfixLogo from "../../static/img/bigfix_logo_white.png"
import Spinner from '../spinner/Spinner'
import LogoutModal from '../logout-modal/LogoutModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import * as serverConstants from '../constants/ServerConstants'
import * as constants from '../constants/Constants'
import * as error from '../constants/ErrorCodes'
import * as cacheUtil from '../util/CacheUtility'
import * as ErrorUtil from '../util/ErrorUtility'
import * as SidebarUtil from '../util/SidebarUtility'
import * as enterpriseUtil from '../util/EnterpriseUtility'
import * as rebrandingUtil from '../util/RebrandingUtility'


class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            showSpinner: false,
            showError: false,
            errorMsg: '',
            enterpriseResponse: '',
            isAuthenticated: true,
            childActiveLink: 'registerEnterprise',
            showOrHideButton: false,
            isRedirect: false,
            windowSize: window.innerWidth
        }
        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        this.getEnterPriseRegDetails()
        window.addEventListener("resize", this.handleResize)
        rebrandingUtil.setBrowserTab()
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize)
    }
    handleResize() {
        this.setState({ windowSize: window.innerWidth })
    }

    getEnterPriseRegDetails() {
        this.setState({ showSpinner: true })
        enterpriseUtil.getEnterPriseRegDetails()
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (response.status === error.HTTP_BAD_GATEWAY) this.handleServerDownError()
                else if (response.status === error.HTTP_FAILED_DEPENDENCY) ErrorUtil.handleFailedDependency(this, response)
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    let responseData = Buffer.from(response.response, "base64").toString()
                    responseData = JSON.parse(responseData)
                    if (responseData) {
                        if (responseData.enterpriseDetails) {
                            var enterpriseDetails = JSON.parse(responseData.enterpriseDetails)
                            if (!enterpriseDetails.signinDetails
                                || (enterpriseDetails.signinDetails && enterpriseDetails.signinDetails[0]
                                    && enterpriseDetails.signinDetails[0].signinUrl !== window.location.origin + constants.ENROLL_TYPE_PARAM + constants.ENROLL_TYPE_ZTD)) {
                                // Updating sign-in details when google API response is failed to give the sign-in details and sign-in enrollment token.
                                enterpriseUtil.updateEnterpriseSigninDetail(responseData.enterpriseDisplayName ? responseData.enterpriseDisplayName : '')
                            }
                        }
                        this.setState({ enterpriseResponse: responseData })
                        this.setState({ showSpinner: false })
                    }
                }
            })
            .catch((error) => {
                this.defaultError(error)
            })
    }

    setModalClose() {
        this.setState({ showModal: false })
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    defaultError(error) {
        this.setState({ showError: true })
        this.setState({ errorMsg: error })
        this.setState({ showSpinner: false })
    }

    handleServerDownError() {
        this.setState({ showError: true })
        this.setState({ showOrHideButton: true })
        this.setState({ errorMsg: error.HTTP_BAD_GATEWAY + ": " + this.props.t('errorDefault') })
        this.setState({ showSpinner: false })
        return false
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {this.state.showModal && <LogoutModal state={this.state} logoutUrl='/config' setModalClose={this.setModalClose.bind(this)} />}
            {!this.state.isAuthenticated && <Redirect to='/config?session=false' />}
            {this.state.isRedirect && <Redirect to='/config/enterprise' />}
            {this.state.showError && <Toast
                iconDescription="close"
                title={this.state.errorMsg}
                type="danger"
                visible
                closable
                onClose={() => { this.setState({ showError: false }) }}
            />}
            <Header
                icons={[
                    {
                        icon: <FontAwesomeIcon icon={faSignOutAlt} onClick={() => this.setState({ showModal: true })} />
                    }
                ]}
                logo={<img alt="Logo" className="bigfix-logo" src={rebrandingUtil.isCompanyLogoImageConfigured() ? rebrandingUtil.getCompanyLogoImage() : BigfixLogo} />}
                style={{ background: rebrandingUtil.setCompanyLogoBg() }}
            />
            <div className={`hcl-container p-0 registration-container`}>
                <div className="page-heading">
                    <Heading type="h5" >
                        {this.props.t('adminConfiguration')}
                    </Heading>
                </div>
                <div className='hcl-row register-wrapper'>
                    <div className='hcl-col-2'>
                        <Sidebar
                            expanded={(this.state.windowSize < constants.MAX_LEFT_PANEL_SIZE) ? false : true}
                            className={this.state.childActiveLink}
                            title={this.props.t('androidSettings')}
                            icon={<i className="p-hclsw p-hclsw-setting"></i>}
                            items={SidebarUtil.getSidebarItems(this.props.t)}
                            type="internal"
                            onClick={(e) => {
                                this.setState({ childActiveLink: e.type })
                                if (e.type === 'registerEnterprise') {
                                    this.getEnterPriseRegDetails()
                                }
                            }}
                        />
                    </div>
                    <div className='hcl-col-9'>
                        {(this.state.childActiveLink === 'registerEnterprise' && this.state.enterpriseResponse !== '')
                            && <EnterpriseRegistration enterpriseResponse={this.state.enterpriseResponse}
                                getEnterPriseRegDetails={this.getEnterPriseRegDetails.bind(this)}
                                showOrHideButton={this.state.showOrHideButton} />}
                        {this.state.childActiveLink === 'manageToken' && <ManageToken />}
                        {this.state.childActiveLink === 'zeroTouchEnterprise' && <ZeroTouchConfiguration windowSize={this.state.windowSize} />}
                        {this.state.childActiveLink === 'privateAppsConfiguration' && <PrivateAppsConfiguration />}
                        {this.state.childActiveLink === 'rebrandingUI' && <RebrandingUI />}
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
}

export default withTranslation()(Main)