import '@patron/patron-css/patron/index.css'
import React from "react"
import { CircleProgressIndicator } from "@patron/patron-react/progressindicator"
import { useTranslation } from 'react-i18next'

import './Spinner.scss'

function Spinner() {
    const { t } = useTranslation()

    return (<React.Fragment>
        <div className="spinner-overlay">
            <CircleProgressIndicator type="indeterminate" progressSize="large"/>
            <label className="ml-5">{t('loading')} ...</label>
        </div>

    </React.Fragment>)
}

export default Spinner