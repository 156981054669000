import '@patron/patron-css/patron/index.css'
import React, { Component } from "react"
import { Heading } from "@patron/patron-react/heading"
import { Label } from "@patron/patron-react/label"
import { Button } from "@patron/patron-react/button"
import { TextInput } from "@patron/patron-react/textinput"
import { Checkbox } from "@patron/patron-react/checkbox"
import { Link } from 'react-router-dom'
import { Notification } from "@patron/patron-react/notification"
import base64 from 'react-native-base64'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router'

import './EnterpriseRegistration.scss'
import Spinner from '../../spinner/Spinner'
import * as serverConstants from '../../constants/ServerConstants'
import * as constants from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as cacheUtil from '../../util/CacheUtility'
import * as ErrorUtil from '../../util/ErrorUtility'
import * as EmailUtil from '../../util/EmailUtility'

class EnterpriseRegistration extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false,
            showError: false,
            errorMsg: false,
            showSuccess: false,
            successMsg: '',
            isAuthenticated: true,
            enterpriseDisplayName: '',
            dataProtectionOfficerName: '',
            dataProtectionOfficerEmail: '',
            dataProtectionOfficerPhone: '',
            euRepresentativeName: '',
            euRepresentativeEmail: '',
            euRepresentativePhone: '',
            contactEmail: '',
            termsAndConditions: false,
            validDataProtectionOfficerEmail: false,
            validEuRepresentativeEmail: false,
            validContactEmail: false,
            validDataProtectionOfficerPhone: false,
            validEuRepresentativePhone: false
        }
    }

    componentDidMount() {
        if (this.props.enterpriseResponse.enterpriseId !== '') {
            this.setEnterpriseRegDetails()
        }
    }

    setEnterpriseRegDetails() {
        this.setState({ enterpriseDisplayName: this.props.enterpriseResponse.enterpriseDisplayName ? this.props.enterpriseResponse.enterpriseDisplayName : '' })
        if (this.props.enterpriseResponse.enterpriseDetails) {
            var enterpriseDetails = JSON.parse(this.props.enterpriseResponse.enterpriseDetails)
            if (enterpriseDetails.contactInfo) {
                var contactInfo = enterpriseDetails.contactInfo
                this.setState({ dataProtectionOfficerName: contactInfo.dataProtectionOfficerName ? contactInfo.dataProtectionOfficerName : '' })
                this.setState({ dataProtectionOfficerEmail: contactInfo.dataProtectionOfficerEmail ? contactInfo.dataProtectionOfficerEmail : '' })
                this.setState({ dataProtectionOfficerPhone: contactInfo.dataProtectionOfficerPhone ? contactInfo.dataProtectionOfficerPhone : '' })
                this.setState({ euRepresentativeName: contactInfo.euRepresentativeName ? contactInfo.euRepresentativeName : '' })
                this.setState({ euRepresentativeEmail: contactInfo.euRepresentativeEmail ? contactInfo.euRepresentativeEmail : '' })
                this.setState({ euRepresentativePhone: contactInfo.euRepresentativePhone ? contactInfo.euRepresentativePhone : '' })
                this.setState({ contactEmail: contactInfo.contactEmail ? contactInfo.contactEmail : '' })
            }
        }
    }

    registerEnterprise(method) {
        this.setState({ showSpinner: true })
        this.setState({ showSuccess: false })
        this.setState({ showError: false })
        window.scroll({ top: 0, behavior: 'smooth' })
        fetch(window.location.origin + serverConstants.ADMIN_COMMAND_URL, {
            "method": "POST",
            "headers": {
                "Authorization": cacheUtil.readFromCache(serverConstants.BFCONFIG_SID)
            },
            "body": this.getBody(method)
        })
            .then((response) => {
                if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) this.handleUnauthorizedResponse()
                else if (!response.ok) {
                    this.setState({ showSpinner: false })
                    this.setState({ showError: true })
                    this.setState({ errorMsg: ErrorUtil.transactionErrorDetails(response, this.props.t) })
                }
                else return response.json()
            })
            .then(response => {
                if (response) {
                    this.props.getEnterPriseRegDetails()
                    this.setState({ showSuccess: true })
                    if (method === constants.HTTP_POST) {
                        this.setState({ successMsg: this.props.t('enterpriseRegisterSuccessMsg') })
                    } else if (method === constants.HTTP_PATCH) {
                        this.setState({ successMsg: this.props.t('enterpriseUpdateSuccessMsg') })
                    }
                    this.setState({ showSpinner: false })
                }
            })
            .catch((error) => {
                this.setState({ showSpinner: false })
                this.setState({ showError: true })
                this.setState({ errorMsg: error })
            });
    }

    handleUnauthorizedResponse() {
        this.setState({ showSpinner: false })
        cacheUtil.clearCacheItem(serverConstants.BFCONFIG_SID)
        this.setState({ isAuthenticated: false })
        return false
    }

    getPayLoad() {
        var payload = {
            "contactInfo": {
                "dataProtectionOfficerName": this.state.dataProtectionOfficerName,
                "dataProtectionOfficerEmail": this.state.dataProtectionOfficerEmail,
                "dataProtectionOfficerPhone": this.state.dataProtectionOfficerPhone,
                "euRepresentativeName": this.state.euRepresentativeName,
                "euRepresentativeEmail": this.state.euRepresentativeEmail,
                "euRepresentativePhone": this.state.euRepresentativePhone,
                "contactEmail": this.state.contactEmail
            },
            "enterpriseDisplayName": this.state.enterpriseDisplayName
        }
        return payload
    }

    getBody(method) {
        var payload = this.getPayLoad()
        var body = JSON.stringify({
            path_param: "enterprises",
            target: "mdm",
            payload: base64.encode(JSON.stringify(payload)),
            query_param: { "agreementAccepted": "true" }
        })

        if (constants.HTTP_PATCH === method) {
            body = JSON.stringify({
                path_param: "enterprises",
                target: "mdm",
                payload: base64.encode(JSON.stringify(payload)),
                requestType: constants.HTTP_PATCH,
                query_param: { "updateMask": constants.CONTACT_INFO + "," + constants.ENTERPRISE_DISPLAY_NAME }
            })
        }
        return body
    }

    phoneNumberValidation(phoneNumber) {
        if (phoneNumber !== '' && !new RegExp(/^[0-9\b+\-()]+/).test(phoneNumber)) {
            return true
        }
        return false
    }

    render() {
        return (<React.Fragment>
            {this.state.showSpinner && <Spinner />}
            {!this.state.isAuthenticated && <Redirect to='/config?session=false' />}
            <div className="hcl-container">
                <Notification
                    onClose={() => { this.setState({ showError: false }) }}
                    title={this.state.errorMsg}
                    type="danger"
                    visible={this.state.showError}
                />
                <Notification
                    onClose={() => { this.setState({ showSuccess: false }) }}
                    title={this.state.successMsg}
                    type="success"
                    visible={this.state.showSuccess}
                />
                {this.props.enterpriseResponse.enterpriseId === '' && <div>
                    <div className="mt-4">
                        <Heading type="h4" >
                            {this.props.t('registerManagedGooglePlay')}
                        </Heading>
                    </div>
                    <Label className="mt-5">
                        {this.props.t('registerManagedGooglePlayDesc')}
                    </Label>
                </div>}
                {this.props.enterpriseResponse.enterpriseId !== '' && <div>
                    <div className="mt-4">
                        <Heading type="h5" >
                            {this.props.t('registeredManagedGooglePlayAccounts')}
                        </Heading>
                    </div>
                    <section className="mt-5 p-5 inline-edit-form">
                        <form>
                            <div className="form-group">
                                <div className="left-section">
                                    <label>{this.props.t('enterpriseId')}: </label>
                                </div>
                                <div className="right-section">
                                    <label>{this.props.enterpriseResponse.enterpriseId}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="left-section">
                                    <label>{this.props.t('accountType')}: </label>
                                </div>
                                <div className="right-section">
                                    <label>{this.props.enterpriseResponse.accountType}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="left-section">
                                    <label>{this.props.t('serviceAccount')}: </label>
                                </div>
                                <div className="right-section">
                                    <label>{this.props.enterpriseResponse.serviceAccount}</label>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>}
                {(this.props.enterpriseResponse.enterpriseId === ''
                    || (this.props.enterpriseResponse.enterpriseId !== '' && this.props.enterpriseResponse.accountType === constants.NON_GOOGLE_WORKSPACE_ACCOUNT))
                    && <form>
                        <div id="displayNameContactInfo">
                            <section className="mt-2 parameters-section">
                                <div className="hcl-row">
                                    <div className="hcl-col-6">
                                        <Label className="mt-2 ml-1">{this.props.t('enterpriseDisplayName')}</Label>
                                        <div className="hcl-row">
                                            <div className="mt-2 ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    type="text"
                                                    id="businessName"
                                                    maxLength={100}
                                                    value={this.state.enterpriseDisplayName}
                                                    onChange={(e) => {
                                                        const value = e.currentTarget.value
                                                        this.setState({ enterpriseDisplayName: value })
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="businessName">{this.props.t('businessName')}<span className="span-style">*</span></Label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hcl-col-6">
                                        <Label className="mt-2 ml-1">{this.props.t('contactInfo')}</Label>
                                        <div className="hcl-row">
                                            <div className="mt-2 ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    id="contactInfoEmail"
                                                    aria-label="text input"
                                                    data-invalid={this.state.validContactEmail}
                                                    value={this.state.contactEmail}
                                                    onChange={(e) => {
                                                        this.setState({ validContactEmail: false })
                                                        const value = e.currentTarget.value
                                                        if (EmailUtil.EmailAddressValidation(value)) {
                                                            this.setState({ validContactEmail: true })
                                                        } else {
                                                            this.setState({ contactEmail: value })
                                                        }
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="contactInfoEmail">{this.props.t('contactEmail')}</Label>
                                                {this.state.validContactEmail &&
                                                    <div className="hcl-error-msg">
                                                        {this.props.t('notAValidEmailAddress')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div id="dataAndEU">
                            <section className="mt-2 parameters-section">
                                <div className="hcl-row">
                                    <div className="hcl-col-6">
                                        <Label className="mt-2 ml-1">{this.props.t('dataProtectionOfficer')}</Label>
                                        <div className="hcl-row">
                                            <div className="mt-2 ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    type="text"
                                                    id="dataProtectionOfficerName"
                                                    value={this.state.dataProtectionOfficerName}
                                                    onChange={(e) => {
                                                        const value = e.currentTarget.value
                                                        this.setState({ dataProtectionOfficerName: value })
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="dataProtectionOfficerName">{this.props.t('name')}</Label>
                                            </div>
                                        </div>
                                        <div className="hcl-row">
                                            <div className="ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    id="dataProtectionOfficerEmail"
                                                    aria-label="text input"
                                                    data-invalid={this.state.validDataProtectionOfficerEmail}
                                                    value={this.state.dataProtectionOfficerEmail}
                                                    onChange={(e) => {
                                                        this.setState({ validDataProtectionOfficerEmail: false })
                                                        const value = e.currentTarget.value
                                                        if (EmailUtil.EmailAddressValidation(value)) {
                                                            this.setState({ validDataProtectionOfficerEmail: true })
                                                        } else {
                                                            this.setState({ dataProtectionOfficerEmail: value })
                                                        }
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="dataProtectionOfficerEmail">{this.props.t('email')}</Label>
                                                {this.state.validDataProtectionOfficerEmail &&
                                                    <div className="hcl-error-msg">
                                                        {this.props.t('notAValidEmailAddress')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="hcl-row">
                                            <div className="ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    id="dataProtectionOfficerPhone"
                                                    aria-label="text input"
                                                    data-invalid={this.state.validDataProtectionOfficerPhone}
                                                    value={this.state.dataProtectionOfficerPhone}
                                                    onChange={(e) => {
                                                        this.setState({ validDataProtectionOfficerPhone: false })
                                                        const value = e.currentTarget.value
                                                        if (this.phoneNumberValidation(value)) {
                                                            this.setState({ validDataProtectionOfficerPhone: true })
                                                        } else {
                                                            this.setState({ dataProtectionOfficerPhone: value })
                                                        }
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="dataProtectionOfficerPhone">{this.props.t('phone')}</Label>
                                                {this.state.validDataProtectionOfficerPhone &&
                                                    <div className="hcl-error-msg">
                                                        {this.props.t('notAValidPhoneNumber')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hcl-col-6">
                                        <Label className="mt-2 ml-1">{this.props.t('euRepresentative')}</Label>
                                        <div className="hcl-row">
                                            <div className="mt-2 ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    type="text"
                                                    id="euRepresentativeName"
                                                    value={this.state.euRepresentativeName}
                                                    onChange={(e) => {
                                                        const value = e.currentTarget.value
                                                        this.setState({ euRepresentativeName: value })
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="euRepresentativeName">{this.props.t('name')}</Label>
                                            </div>
                                        </div>
                                        <div className="hcl-row">
                                            <div className="ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    id="euRepresentativeEmail"
                                                    aria-label="text input"
                                                    data-invalid={this.state.validEuRepresentativeEmail}
                                                    value={this.state.euRepresentativeEmail}
                                                    onChange={(e) => {
                                                        this.setState({ validEuRepresentativeEmail: false })
                                                        const value = e.currentTarget.value
                                                        if (EmailUtil.EmailAddressValidation(value)) {
                                                            this.setState({ validEuRepresentativeEmail: true })
                                                        }
                                                        else {
                                                            this.setState({ euRepresentativeEmail: value })
                                                        }
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="euRepresentativeEmail">{this.props.t('email')}</Label>
                                                {this.state.validEuRepresentativeEmail &&
                                                    <div className="hcl-error-msg">
                                                        {this.props.t('notAValidEmailAddress')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="hcl-row">
                                            <div className="ml-2 hcl-col-8 hcl-form-group">
                                                <TextInput
                                                    id="euRepresentativePhone"
                                                    aria-label="text input"
                                                    data-invalid={this.state.validEuRepresentativePhone}
                                                    value={this.state.euRepresentativePhone}
                                                    onChange={(e) => {
                                                        this.setState({ validEuRepresentativePhone: false })
                                                        const value = e.currentTarget.value
                                                        if (this.phoneNumberValidation(value)) {
                                                            this.setState({ validEuRepresentativePhone: true })
                                                        } else {
                                                            this.setState({ euRepresentativePhone: value })
                                                        }
                                                    }}
                                                    required
                                                />
                                                <Label htmlFor="euRepresentativePhone">{this.props.t('phone')}</Label>
                                                {this.state.validEuRepresentativePhone &&
                                                    <div className="hcl-error-msg">
                                                        {this.props.t('notAValidPhoneNumber')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div id="termsAndConditions">
                            <div className="hcl-row">
                                <div className="mt-2 ml-3">
                                    <Checkbox
                                        id="termsConditions"
                                        label={this.props.t('termsAndConditions')}
                                        checked={this.state.termsAndConditions}
                                        onChange={() => {
                                            this.setState({ termsAndConditions: !this.state.termsAndConditions })
                                        }}
                                    />
                                </div> &nbsp;
                                <div className="mt-3 hcl-form-group">
                                    <Link
                                        to={{ pathname: serverConstants.ANDROID_TERMS_AND_CONDITIONS }}
                                        target="_blank"
                                        title={this.props.t('managedGooglePlayAgreement')}
                                        rel="noopener noreferrer"
                                    >
                                        {this.props.t('managedGooglePlayAgreement')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {(!this.props.enterpriseResponse.hasToken && this.props.enterpriseResponse.enterpriseId === '') &&
                            <div className="hcl-type-zeta">
                                {this.props.t('invalidTokenForEnterpriseMsg')}
                            </div>}
                        <div className="register-button">
                            {this.props.enterpriseResponse.enterpriseId === '' ?
                                <Button
                                    type="primary"
                                    disabled={this.state.showSpinner || !this.state.termsAndConditions || this.state.enterpriseDisplayName === ""
                                        || this.props.showOrHideButton || !this.props.enterpriseResponse.hasToken || this.state.validDataProtectionOfficerEmail
                                        || this.state.validEuRepresentativeEmail || this.state.validContactEmail
                                        || this.state.validDataProtectionOfficerPhone || this.state.validEuRepresentativePhone}
                                    onClick={() => {
                                        this.registerEnterprise(constants.HTTP_POST)
                                    }}
                                >
                                    {this.props.t('register')}
                                </Button> :
                                <Button
                                    type="primary"
                                    disabled={this.state.showSpinner || !this.state.termsAndConditions || this.state.enterpriseDisplayName === "" || this.props.showOrHideButton
                                        || this.state.validDataProtectionOfficerEmail || this.state.validEuRepresentativeEmail || this.state.validContactEmail
                                        || this.state.validDataProtectionOfficerPhone || this.state.validEuRepresentativePhone}
                                    onClick={() => {
                                        this.registerEnterprise(constants.HTTP_PATCH)
                                    }}
                                >
                                    {this.props.t('update')}
                                </Button>
                            }
                        </div>
                    </form>}
            </div>
        </React.Fragment>)
    }
}

export default withTranslation()(EnterpriseRegistration)