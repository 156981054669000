import '@patron/patron-css/patron/index.css'
import React, { useState, useEffect } from "react"
import { Button } from "@patron/patron-react/button"
import { Paragraph } from "@patron/patron-react/paragraph"
import { TextInput } from "@patron/patron-react/textinput"
import { Label } from "@patron/patron-react/label"
import { Password } from "@patron/patron-react/password"
import { Checkbox } from "@patron/patron-react/checkbox"
import { Notification } from "@patron/patron-react/notification"
import { Dropdown } from "@patron/patron-react/dropdown"
import { Tooltip } from "@patron/patron-react/tooltip"
import { List } from "@patron/patron-react/list"
import { Link } from 'react-router-dom'
import base64 from 'react-native-base64'
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom"

import "./EnrollWithLdap.scss"
import CompanyHeader from "../../companyheader/CompanyHeader"
import * as ServerConstants from '../../constants/ServerConstants'
import * as Constant from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as EnrollUtil from '../../util/EnrollUtility'
import * as ErrorUtil from '../../util/ErrorUtility'
import * as cacheUtil from '../../util/CacheUtility'
import * as rebrandingUtil from '../../util/RebrandingUtility'
import * as EmailUtil from '../../util/EmailUtility'
import QRModal from '../../qr-modal/QRModal'

function EnrollWithLdap() {

    const { t } = useTranslation()
    const [qrEnroll, setQrEnroll] = useState("")
    const [userName, setUserName] = useState(cacheUtil.readFromCache(Constant.ENROLL_USER_NAME) ? cacheUtil.readFromCache(Constant.ENROLL_USER_NAME) : '')
    const [password, setPassword] = useState(cacheUtil.readFromCache(Constant.ENROLL_PASSWORD) ? cacheUtil.readFromCache(Constant.ENROLL_PASSWORD) : '')
    const [rememberMe, setRememberPassword] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const [showUserWar, setShowUserWar] = useState(false)
    const [disableEnrollButton, setDisableEnrollButton] = useState(false)
    const [appleOwnershipTypeID, setAppleOwnershipTypeID] = useState(Constant.INSTITUTIONALLY_OWNED)
    const [managedAppleID, setManagedAppleID] = useState("")
    const [androidQREnrollCheck, setAndroidQREnrollCheck] = useState(false)
    const [validAppleIDEmail, setValidAppleIDEmail] = useState(false)
    const [showQRModal, setQRModal] = useState(false)
    const [qrImg, setQRImg] = useState("")
    const [hideBYOD, setHideBYOD] = useState(true)
    const [isDEPEnrolmentFlow, setIsDEPEnrolmentFlow] = useState(false)

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()

    useEffect(() => {
        if (cacheUtil.readFromCache(Constant.ENROLL_REMEMBER_ME)) {
            setRememberPassword(cacheUtil.readFromCache(Constant.ENROLL_REMEMBER_ME).toLowerCase() === Constant.TRUE)
        }
        rebrandingUtil.setBrowserTab()
        if (query.has('HIDE_BYOD')) {
            const queryParamValue = query.get('HIDE_BYOD')
            setIsDEPEnrolmentFlow(queryParamValue && queryParamValue === "true")
        }
        (isDEPEnrolmentFlow) ? setHideBYOD(true) : setHideBYOD(!window.globalConfig.APPLE_BYOD)
    }, [password, isDEPEnrolmentFlow, query])

    const rememberMeHandler = () => {
        setRememberPassword(!rememberMe)
        if (rememberMe) {
            cacheUtil.clearCacheItem(Constant.ENROLL_REMEMBER_ME)
            cacheUtil.clearCacheItem(Constant.ENROLL_USER_NAME)
            cacheUtil.clearCacheItem(Constant.ENROLL_PASSWORD)
        }
    }

    function rememberMeLocalStorage() {
        cacheUtil.storeToCache(Constant.ENROLL_REMEMBER_ME, rememberMe)
        cacheUtil.storeToCache(Constant.ENROLL_USER_NAME, userName)
        cacheUtil.storeToCache(Constant.ENROLL_PASSWORD, password)
    }

    const enroll = () => {
        setDisableEnrollButton(true)
        setNotificationsFalse()
        setAndroidQREnrollCheck(false)
        if (EmailUtil.EmailAddressValidation(userName)) {
            setDisableEnrollButton(false)
            setShowUserWar(true)
        } else if (appleOwnershipTypeID === Constant.PERSONALLY_OWNED && managedAppleID !== ''
            && EmailUtil.EmailAddressValidation(managedAppleID)) {
            setDisableEnrollButton(false)
            setValidAppleIDEmail(true)
        } else {
            if (rememberMe) {
                rememberMeLocalStorage()
            }
            if (EnrollUtil.IsQREnroll(qrEnroll)) {
                apiQrEnroll()
            } else {
                apiEnroll()
            }
        }
    }

    function setNotificationsFalse() {
        setShowUserWar(false)
        setShowError(false)
        setShowSuccess(false)
        setValidAppleIDEmail(false)
    }

    function apiEnroll() {
        validateUserID()
    }

    function apiQrEnroll() {
        login(true)
    }

    function validateUserID() {
        fetch(window.location.origin + ServerConstants.EMAIL_VALIDATE + encodeURIComponent(userName), {
            "method": "GET"
        })
            .then((response) => {
                if (!response.ok) {
                    setDisableEnrollButton(false)
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    return false
                }
                else return response
            })
            .then(response => {
                if (response) {
                    login(false)
                }
            })
            .catch(() => {
                loginErrorResponse()
            })
    }

    function loginErrorResponse() {
        setDisableEnrollButton(false)
        setShowError(true)
        setErrorMsg(t('unableToLogin'))
    }

    function login(isWithQRCode) {
        fetch(window.location.origin + ServerConstants.MDM_LOGIN + "?t=" + (new Date().getTime()), {
            "method": "GET",
            "headers": {
                "Authorization": 'Basic ' + base64.encode(userName + ":" + password)
            }
        })
            .then((response) => {
                if (response.status === error.HTTP_FORBIDDEN) {
                    setDisableEnrollButton(false)
                    setShowError(true)
                    setErrorMsg(error.HTTP_LOCKED + ": " + t('loginAttemptsExceeded'))
                    return false
                } else if (!response.ok) {
                    setDisableEnrollButton(false)
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    return false
                }
                else return response
            })
            .then(response => {
                if (response) {
                    if (!isWithQRCode) {
                        postToServer()
                    } else {
                        postToServerQrEnroll()
                    }
                }
            })
            .catch(() => {
                loginErrorResponse()
            })
    }

    function postToServer() {
        if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_WIN) {
            winEnroll()
        } else if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) {
            let dest = new URL(EnrollUtil.getServerURL() + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(userName), window.location)
            if (appleOwnershipTypeID === Constant.PERSONALLY_OWNED && managedAppleID !== '') {
                dest = new URL(window.location.origin + ServerConstants.USER_ENROLL + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(userName) + "&managed=" + encodeURIComponent(managedAppleID), window.location)
            }
            window.location = dest.href
        } else if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_ANDROID) {
            let dest = new URL(EnrollUtil.getServerURL() + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(userName), window.location)
            enrollAndroidDevice(dest.href)
        }
        loginSuccess()
    }

    function enrollAndroidDevice(url) {
        fetch(url)
            .then((response) => {
                return response.text()
            })
            .then((response) => {
                window.location = response
            })
            .catch(() => {
                enrollErrorResponse()
            })
    }

    function winEnroll() {
        let dest = new URL(ServerConstants.MS_DEVICE_ENROLL_WITH_LDAP + encodeURIComponent(userName) + ServerConstants.MS_SERVER_NAME_WITH_LDAP + window.location.origin + ServerConstants.WIN_ENROLL, window.location)
        window.location = dest.href
        return false
    }

    function postToServerQrEnroll() {
        let serverUrl = EnrollUtil.getQREnrollURlWithLDAP(qrEnroll, userName)
        fetch(serverUrl, {
            "method": "GET"
        })
            .then((response) => {
                disableEnrollBasedOnOS()
                if (!response.ok) {
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    return false
                }
                else return response.text()
            })
            .then(response => {
                if (response) {
                    downloadQRImage(response)
                    loginSuccess()
                }
            })
            .catch(() => {
                disableEnrollBasedOnOS()
                enrollErrorResponse()
            })
    }

    function loginSuccess() {
        disableEnrollBasedOnOS()
        setShowSuccess(true)
        setSuccessMsg(t('userAuthenticatedSuccessfully'))
    }

    function enrollErrorResponse() {
        setDisableEnrollButton(false)
        setShowError(true)
        setErrorMsg(t('unableToEnroll'))
    }

    function disableEnrollBasedOnOS() {
        if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_WIN) {
            setTimeout(() => {
                disableEnroll()
            }, 4000)
        } else {
            setQrEnroll("")
            window.setTimeout(disableEnroll, 4000)
        }
    }

    function disableEnroll() {
        setQrEnroll("")
        setDisableEnrollButton(false)
        setShowSuccess(false)
        setShowError(false)
    }

    const downloadQRImage = async (imageUrl) => {
        const res = await fetch(imageUrl)
        const imageBlob = await res.blob()
        const imageObjectURL = URL.createObjectURL(imageBlob)
        setQRImg(imageObjectURL)
        setQRModal(true)
    }
    
    const setModalClose = () => {
        setQRModal(false)
        setQRImg("")
    }

    return (
        <React.Fragment>
            <CompanyHeader />
            <section className="hcl-container hcl-flex p-0 enroll-container" style={rebrandingUtil.rebrandingUIBackgroundStyle()}>
                <div>
                    <div className="ldap-enroll-wrapper" style={{ background: rebrandingUtil.setBrandLogoPanelBg() }}>
                        <div className="hcl-text-center">
                            <img
                                src={rebrandingUtil.setBrandLogo()}
                                alt="product logo"
                            />
                        </div>
                        <Notification
                            onClose={() => { setShowError(false) }}
                            title={errorMsg}
                            type="danger"
                            visible={showError}
                        />
                        <Notification
                            onClose={() => { setShowSuccess(false) }}
                            title={successMsg}
                            type="success"
                            visible={showSuccess}
                        />
                        <Notification
                            onClose={() => { setShowUserWar(false) }}
                            title={t('emailAddressWar') + userName + t('emailAddressWar@Missing')}
                            type="warning"
                            visible={showUserWar}
                        />
                        <Notification
                            onClose={() => { setValidAppleIDEmail(false) }}
                            title={t('notAValidManagedAppleID') + managedAppleID + "'"}
                            type="warning"
                            visible={validAppleIDEmail}
                        />
                        <form className="p-5">
                            <div className="hcl-form-group">
                                <TextInput
                                    type="text"
                                    placeholder={t('emailAddress')}
                                    id="username"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    required
                                />
                                <Label htmlFor="username">{t('emailAddress')}</Label>
                            </div>
                            <div className="hcl-form-group">
                                <Password
                                    placeholder={t('password')}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <Label htmlFor="password">{t('password')}</Label>
                            </div>
                            {query.get('enrollType') !== Constant.ENROLL_TYPE_ZTD && <div>
                                <div className="hcl-flex mb-2 enroll-options">
                                    <Checkbox
                                        id="remember"
                                        label={t('rememberUser')}
                                        checked={rememberMe}
                                        onChange={rememberMeHandler}
                                    />
                                </div>
                                {(EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) && !androidQREnrollCheck && !hideBYOD &&
                                    <div>
                                        <div className='hcl-row'>
                                            <div className='hcl-col-9'>
                                                <Paragraph className="hcl-flex mb-2 enroll-ownership-label">
                                                    {t('specifyOwnershipTypeOfDevice')}
                                                </Paragraph>
                                            </div>
                                            <div className='hcl-col-2'>
                                                <Tooltip type="interactive" className='tooltip-style' content={<div>
                                                    <Paragraph className="hcl-flex mb-2">
                                                        {t('personallyOwnedDevices')}
                                                    </Paragraph>
                                                    <List
                                                        listItems={EnrollUtil.getAppleTooltipList(t)}
                                                    /></div>}>
                                                    <svg
                                                        className="hcl-interactive-tooltip ml-10 mt-1"
                                                        focusable="false"
                                                        height="16"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        viewBox="0 0 16 16"
                                                        width="16"
                                                        xmlns="https://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M8.5 11V6.5h-2v1h1V11H6v1h4v-1zM8 3.5c-.4 0-.8.3-.8.8s.4.7.8.7.8-.3.8-.8-.4-.7-.8-.7z" />
                                                        <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                                                    </svg>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Dropdown
                                            items={[
                                                {
                                                    id: Constant.PERSONALLY_OWNED,
                                                    text: t('personallyOwned')
                                                },
                                                {
                                                    id: Constant.INSTITUTIONALLY_OWNED,
                                                    text: t('institutionallyOwned')
                                                }
                                            ]}
                                            selectedItem={appleOwnershipTypeID}
                                            onChange={(e) => {
                                                setAppleOwnershipTypeID(e.id)
                                                setManagedAppleID('')
                                            }}
                                            type="bottom"
                                        />
                                        {appleOwnershipTypeID === Constant.PERSONALLY_OWNED &&
                                            <div className="mt-4 hcl-form-group">
                                                <TextInput
                                                    type="text"
                                                    placeholder={t('managedAppleID')}
                                                    id="managedAppleID"
                                                    value={managedAppleID}
                                                    onChange={(e) => setManagedAppleID(e.target.value)}
                                                    required
                                                />
                                                <Label htmlFor="managedAppleID">{t('enterYourManagedAppleIDToInstallMDMProfile')}<span className="span-style">*</span></Label>
                                            </div>}
                                    </div>
                                }
                                { !isDEPEnrolmentFlow &&
                                <div className='hcl-row mt-2'>
                                    <div className='hcl-col-11 hcl-flex mb-2 enroll-options'>
                                        <Checkbox
                                            id="androidQREnrollCheck"
                                            label={t('enrollUsingQRCode')}
                                            checked={androidQREnrollCheck}
                                            onChange={() => {
                                                setAppleOwnershipTypeID(Constant.INSTITUTIONALLY_OWNED)
                                                setAndroidQREnrollCheck(!androidQREnrollCheck)
                                                if (androidQREnrollCheck) setQrEnroll('')
                                            }}
                                        /><span className="hcl-type-legal android-only">{t('forAndroidDevicesOnly')}</span>
                                    </div>
                                    <div className='hcl-col-1'>
                                        <Tooltip type="interactive" className='tooltip-style' content={<div>
                                            <List
                                                listItems={EnrollUtil.getAndroidQREnrollTooltipList(t)}
                                            />
                                        </div>}>
                                            <svg
                                                className="hcl-interactive-tooltip mt-2"
                                                focusable="false"
                                                height="16"
                                                preserveAspectRatio="xMidYMid meet"
                                                viewBox="0 0 16 16"
                                                width="16"
                                                xmlns="https://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.5 11V6.5h-2v1h1V11H6v1h4v-1zM8 3.5c-.4 0-.8.3-.8.8s.4.7.8.7.8-.3.8-.8-.4-.7-.8-.7z" />
                                                <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                                            </svg>
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                                {androidQREnrollCheck &&
                                    <Dropdown
                                        items={EnrollUtil.getEnrollmentTypeList(t)}
                                        selectedItem={qrEnroll}
                                        onChange={(e) => {
                                            setQrEnroll(e.id)
                                        }}
                                        label={t('qREnrollmentType')}
                                        type="bottom"
                                    />}
                            </div>}
                            <div className="hcl-text-center mt-1">
                                <Button
                                    className="hcl-primary"
                                    style={{ background: rebrandingUtil.setButtonColor() }}
                                    disabled={userName === "" || password === "" || disableEnrollButton || (androidQREnrollCheck && qrEnroll === '') ||
                                        ((EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) && (appleOwnershipTypeID === Constant.PERSONALLY_OWNED && managedAppleID === ''))}
                                    onClick={enroll}
                                >
                                    {t('enroll')}
                                </Button>
                            </div>
                            <div className='mt-1 hcl-text-center'>
                                <Link
                                    to={{ pathname: ServerConstants.NEED_HELP_ENROLL_PAGE }}
                                    target="_blank"
                                    title={t('needHelpEnrolling')}
                                    rel="noopener noreferrer"
                                >
                                    {t('needHelpEnrolling')}
                                </Link>
                            </div>
                            <Paragraph className="hcl-type-legal mt-4 hcl-text-center">
                                &copy; {rebrandingUtil.setCopyrightMessage(t)}
                            </Paragraph>
                        </form>
                    </div>
                </div>
                {showQRModal && <QRModal qrUrl={qrImg} setModalClose={setModalClose.bind(this)} />}
            </section>
        </React.Fragment>
    )
}

export default EnrollWithLdap