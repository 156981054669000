import '@patron/patron-css/patron/index.css'
import React from "react"
import { Modal } from "@patron/patron-react/modal"

import "./QRModal.scss"

function QRModal(props) {

    function setModalClose() {
        props.setModalClose(false)
    }

    return (
        <React.Fragment>
            <Modal
                onClose={setModalClose}
                type="success"
                width="auto"
                height="auto"
                className="modal-content"
            >
                <img src={props.qrUrl} alt="QR" className='qr-image' />
            </Modal>
        </React.Fragment>
    )
}

export default QRModal