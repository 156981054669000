/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

//Token request email body
export function TOKEN_EMAIL_BODY(secret, hostOrSerialNumber) {
    var hostOrSerialNumberLabel = window.globalConfig.CLOUD_DEPLOYMENT ? "MDM server host: " : "Deployment Serial Number: "
    return (
        "Hi" +
        "\n\n" +
        "This is a request for Enterprise account token. Here are the required fields:" +
        "\n\n" +
        "Secret: " + secret + "\n" +
        hostOrSerialNumberLabel + hostOrSerialNumber +
        "\n\n" +
        "Thanks\n" +
        "<Customer BigFix Admin>"
    )
}
//Token request email subject
export const TOKEN_EMAIL_SUBJECT = 'Enterprise account token request'