import '@patron/patron-css/patron/index.css'
import React, { useState } from "react"
import { Button } from "@patron/patron-react/button"
import { Paragraph } from "@patron/patron-react/paragraph"
import { Notification } from "@patron/patron-react/notification"
import { Checkbox } from "@patron/patron-react/checkbox"
import { Dropdown } from "@patron/patron-react/dropdown"
import { Tooltip } from "@patron/patron-react/tooltip"
import { List } from "@patron/patron-react/list"
import { Link } from 'react-router-dom'
import { TextInput } from "@patron/patron-react/textinput"
import { Label } from "@patron/patron-react/label"
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom"

import "./EnrollWithAzure.scss"
import CompanyHeader from "../../companyheader/CompanyHeader"
import * as ServerConstants from '../../constants/ServerConstants'
import * as Constant from '../../constants/Constants'
import * as error from '../../constants/ErrorCodes'
import * as EnrollUtil from '../../util/EnrollUtility'
import * as ErrorUtil from '../../util/ErrorUtility'
import * as rebrandingUtil from '../../util/RebrandingUtility'
import * as EmailUtil from '../../util/EmailUtility'
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../../util/AzureADAuthConfig"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import Spinner from '../../spinner/Spinner'
import QRModal from '../../qr-modal/QRModal'

function EnrollWithAzure() {

    const { t } = useTranslation()
    const [qrEnroll, setQrEnroll] = useState("")
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [disableEnrollButton, setDisableEnrollButton] = useState(false)
    const [appleOwnershipTypeID, setAppleOwnershipTypeID] = useState(Constant.INSTITUTIONALLY_OWNED)
    const [managedAppleID, setManagedAppleID] = useState("")
    const [androidQREnrollCheck, setAndroidQREnrollCheck] = useState(false)
    const [validAppleIDEmail, setValidAppleIDEmail] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showQRModal, setQRModal] = useState(false)
    const [qrImg, setQRImg] = useState("");
    const { instance, accounts } = useMsal()

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()

    const clearStorage = () => {
        sessionStorage.clear()
        window.location.reload()
    }

    const downloadQRImage = async (imageUrl, token) => {
        const res = await fetch(imageUrl, {
            "headers": {
                "Authorization": token
            },
        })
        const imageBlob = await res.blob()
        const imageObjectURL = URL.createObjectURL(imageBlob)
        setQRImg(imageObjectURL)
        setQRModal(true)
    }

    const setModalClose = () => {
        setQRModal(false)
        setQRImg("")
    }

    const fetchToken = async () => {
        if (instance && instance.getActiveAccount()) {
            const accessTokenRequest = {
                scopes: ["user.read"],
                account: instance.getActiveAccount(),
            }
            setShowSpinner(true)
            try {
                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest)
                if (accessTokenResponse) {
                    const token = accessTokenResponse.idToken
                    enroll(token)
                } else {
                    clearStorage()
                }
                setShowSpinner(false)
            } catch (error) {
                setShowSpinner(false)
                clearStorage()
            }
        } else {
            clearStorage()
        }
    }

    const invokeValidateTokenAPI = (token) => {
        setShowSpinner(true)
        fetch(window.location.origin + ServerConstants.AZURE_AUTH_URL, {
            "method": ServerConstants.REQUEST_TYPE_POST,
            "headers": {
                "Authorization": token
            },
            "body": ""
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === error.HTTP_FORBIDDEN) {
                        clearStorage()
                    } else {
                        setShowError(true)
                        setErrorMsg(t('errorServerError'))
                    }
                }
                else {
                    winEnroll(token)
                }
                setShowSpinner(false)
            })
            .catch(() => {
                setShowError(true)
                setErrorMsg(t('errorServerError'))
                setShowSpinner(false)
            })
    }

    const enroll = (token) => {
        setDisableEnrollButton(true)
        setValidAppleIDEmail(false)
        setShowError(false)
        setAndroidQREnrollCheck(false)
        if (appleOwnershipTypeID === Constant.PERSONALLY_OWNED && managedAppleID !== ''
            && EmailUtil.EmailAddressValidation(managedAppleID)) {
            setDisableEnrollButton(false)
            setValidAppleIDEmail(true)
        } else {
            if (EnrollUtil.IsQREnroll(qrEnroll)) {
                apiQrEnroll(token)
            } else {
                apiEnroll(token)
            }
        }
    }

    function apiEnroll(token) {
        if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_WIN) {
            invokeValidateTokenAPI(token)
        } else if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) {
            let dest = new URL(EnrollUtil.getServerURL() + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(accounts[0].username), window.location)
                if (appleOwnershipTypeID === Constant.PERSONALLY_OWNED && managedAppleID !== '') {
                    dest = new URL(window.location.origin + ServerConstants.USER_ENROLL + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(accounts[0].username) + "&managed=" + encodeURIComponent(managedAppleID), window.location)
                }
                enrollAppleDevice(dest.href, token)
        } else if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_ANDROID) {
            let dest = new URL(EnrollUtil.getServerURL() + ServerConstants.EMAIL_ADDRESS + encodeURIComponent(accounts[0].username), window.location)
            enrollAndroidDevice(dest.href, token)
        }
        disableEnrollBasedOnOS()
    }

    function enrollAndroidDevice(url, token) {
        setShowSpinner(true)
        fetch(url, {
            "headers": {
                Authorization: token,
            },
        })
            .then((response) => {
                setShowSpinner(false)
                if (!response.ok) {
                    if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) {
                        clearStorage()
                    }
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    return false
                }
                else return response.text()
            })
            .then((response) => {
                if (response) {
                    window.location = response
                }
            })
            .catch((err) => {
                setShowSpinner(false)
                enrollErrorResponse()
            })
    }

    function enrollAppleDevice(url, token) {
        setShowSpinner(true)
        fetch(url, {
            "headers": {
                Authorization: token
            },
        })
            .then((response) => {
                setShowSpinner(false)
                if (!response.ok) {
                    if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) {
                        clearStorage()
                    }
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    return false
                }
                else return response.blob()
            })
            .then((blob) => {
                if (blob) {
                    const url = window.URL.createObjectURL(
                        new File([blob], "enroll.mobileconfig", {type: "application/x-apple-aspen-config"}),
                    )
                    window.location = url
                }
            })
            .catch(() => {
                setShowSpinner(false)
                enrollErrorResponse()
            })
    }

    function winEnroll() {
        let dest = new URL(ServerConstants.MS_DEVICE_ENROLL_WITH_LDAP + encodeURIComponent(accounts[0].username) + ServerConstants.MS_SERVER_NAME_WITH_LDAP + window.location.origin + ServerConstants.WIN_ENROLL, window.location)
        window.location = dest.href
        return false
    }

    function apiQrEnroll(token) {
        setShowSpinner(true)
        let serverUrl = EnrollUtil.getQREnrollURlWithLDAP(qrEnroll, accounts[0].username)
        fetch(serverUrl, {
            "headers": {
                "Authorization": token
            },
        })
            .then((response) => {
                setShowSpinner(false)
                if (!response.ok) {
                    if (response.status === error.HTTP_UNAUTHORIZED || response.status === error.HTTP_FORBIDDEN) {
                        clearStorage()
                    }
                    setShowError(true)
                    setErrorMsg(ErrorUtil.transactionErrorDetails(response, t))
                    setDisableEnrollButton(false)
                    return false
                }
                else return response.text()
            })
            .then(response => {
                setShowSpinner(false)
                if (response) {
                    downloadQRImage(response, token)
                    disableEnrollBasedOnOS()
                }
            })
            .catch(() => {
                setShowSpinner(false)
                enrollErrorResponse()
                setDisableEnrollButton(false)
            })
    }

    function enrollErrorResponse() {
        setShowError(true)
        setErrorMsg(t('unableToEnroll'))
    }

    function disableEnrollBasedOnOS() {
        if (EnrollUtil.getPlatformOS() === Constant.PLATFORM_WIN) {
            setTimeout(() => {
                disableEnroll()
            }, 4000)
        } else {
            setQrEnroll("")
            window.setTimeout(disableEnroll, 4000)
        }
    }

    function disableEnroll() {
        setQrEnroll("")
        setDisableEnrollButton(false)
        setShowError(false)
    }

    const handleLogin = () => {
        instance.acquireTokenRedirect(loginRequest).catch(e => {
            setShowError(true)
            setDisableEnrollButton(true)
            setErrorMsg(t('azureADError'))
        });
    }

    return (
        <React.Fragment>
            {showSpinner && <Spinner />}
            <CompanyHeader />
            <section className="hcl-container hcl-flex p-0 enroll-container" style={rebrandingUtil.rebrandingUIBackgroundStyle()}>
                <div>
                    <div className="ldap-enroll-wrapper" style={{ background: rebrandingUtil.setBrandLogoPanelBg() }}>
                        <div className="hcl-text-center">
                            <img
                                src={rebrandingUtil.setBrandLogo()}
                                alt="product logo"
                            />
                        </div>
                        <Notification
                            onClose={() => { setShowError(false) }}
                            title={errorMsg}
                            type="danger"
                            visible={showError}
                        />
                        <Notification
                            onClose={() => { setValidAppleIDEmail(false) }}
                            title={t('notAValidManagedAppleID') + managedAppleID + "'"}
                            type="warning"
                            visible={validAppleIDEmail}
                        />

                        <AuthenticatedTemplate>
                            <form className="p-5">
                                {query.get('enrollType') !== Constant.ENROLL_TYPE_ZTD && <div>
                                    {(EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) && !androidQREnrollCheck && window.globalConfig.APPLE_BYOD &&
                                        <div>
                                            <div className='hcl-row'>
                                                <div className='hcl-col-9'>
                                                    <Paragraph className="hcl-flex mb-2 enroll-ownership-label">
                                                        {t('specifyOwnershipTypeOfDevice')}
                                                    </Paragraph>
                                                </div>
                                                <div className='hcl-col-2'>
                                                    <Tooltip type="interactive" className='tooltip-style' content={<div>
                                                        <Paragraph className="hcl-flex mb-2">
                                                            {t('personallyOwnedDevices')}
                                                        </Paragraph>
                                                        <List
                                                            listItems={EnrollUtil.getAppleTooltipList(t)}
                                                        /></div>}>
                                                        <svg
                                                            className="hcl-interactive-tooltip ml-10 mt-1"
                                                            focusable="false"
                                                            height="16"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            viewBox="0 0 16 16"
                                                            width="16"
                                                            xmlns="https://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M8.5 11V6.5h-2v1h1V11H6v1h4v-1zM8 3.5c-.4 0-.8.3-.8.8s.4.7.8.7.8-.3.8-.8-.4-.7-.8-.7z" />
                                                            <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <Dropdown
                                                items={[
                                                    {
                                                        id: Constant.PERSONALLY_OWNED,
                                                        text: t('personallyOwned')
                                                    },
                                                    {
                                                        id: Constant.INSTITUTIONALLY_OWNED,
                                                        text: t('institutionallyOwned')
                                                    }
                                                ]}
                                                selectedItem={appleOwnershipTypeID}
                                                onChange={(e) => {
                                                    setAppleOwnershipTypeID(e.id)
                                                    setManagedAppleID('')
                                                }}
                                                type="bottom"
                                            />
                                            {appleOwnershipTypeID === Constant.PERSONALLY_OWNED &&
                                                <div className="mt-4 hcl-form-group">
                                                    <TextInput
                                                        type="text"
                                                        placeholder={t('managedAppleID')}
                                                        id="managedAppleID"
                                                        value={managedAppleID}
                                                        onChange={(e) => setManagedAppleID(e.target.value)}
                                                        required
                                                    />
                                                    <Label htmlFor="managedAppleID">{t('enterYourManagedAppleIDToInstallMDMProfile')}<span className="span-style">*</span></Label>
                                                </div>}
                                        </div>
                                    }
                                    <div className='hcl-row mt-2'>
                                        <div className='hcl-col-11 hcl-flex mb-2 enroll-options'>
                                            <Checkbox
                                                id="androidQREnrollCheck"
                                                label={t('enrollUsingQRCode')}
                                                checked={androidQREnrollCheck}
                                                onChange={() => {
                                                    setAppleOwnershipTypeID(Constant.INSTITUTIONALLY_OWNED)
                                                    setAndroidQREnrollCheck(!androidQREnrollCheck)
                                                    if (androidQREnrollCheck) setQrEnroll('')
                                                }}
                                            /><span className="hcl-type-legal android-only">{t('forAndroidDevicesOnly')}</span>
                                        </div>

                                        <div className='hcl-col-1'>
                                            <Tooltip type="interactive" className='tooltip-style' content={<div>
                                                <List
                                                    listItems={EnrollUtil.getAndroidQREnrollTooltipList(t)}
                                                />
                                            </div>}>
                                                <svg
                                                    className="hcl-interactive-tooltip mt-2"
                                                    focusable="false"
                                                    height="16"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 16 16"
                                                    width="16"
                                                    xmlns="https://www.w3.org/2000/svg"
                                                >
                                                    <path d="M8.5 11V6.5h-2v1h1V11H6v1h4v-1zM8 3.5c-.4 0-.8.3-.8.8s.4.7.8.7.8-.3.8-.8-.4-.7-.8-.7z" />
                                                    <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                                                </svg>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {androidQREnrollCheck &&
                                        <Dropdown
                                            items={EnrollUtil.getEnrollmentTypeList(t)}
                                            selectedItem={qrEnroll}
                                            onChange={(e) => {
                                                setQrEnroll(e.id)
                                            }}
                                            label={t('qREnrollmentType')}
                                            type="bottom"
                                        />}
                                </div>}
                                <div className="hcl-text-center mt-1">
                                    <Button
                                        className="hcl-primary"
                                        style={{ background: rebrandingUtil.setButtonColor() }}
                                        disabled={(accounts[0] && accounts[0].username === "") || showSpinner || disableEnrollButton || (androidQREnrollCheck && qrEnroll === '') ||
                                            ((EnrollUtil.getPlatformOS() === Constant.PLATFORM_APPLE) && (appleOwnershipTypeID === Constant.PERSONALLY_OWNED && managedAppleID === ''))}
                                        onClick={fetchToken}
                                    >
                                        {t('enroll')}
                                    </Button>
                                </div>
                                <div className='mt-1 hcl-text-center'>
                                    <Link
                                        to={{ pathname: ServerConstants.NEED_HELP_ENROLL_PAGE }}
                                        target="_blank"
                                        title={t('needHelpEnrolling')}
                                        rel="noopener noreferrer">
                                        {t('needHelpEnrolling')}
                                    </Link>
                                </div>
                                <Paragraph className="hcl-type-legal mt-4 hcl-text-center">
                                    &copy; {rebrandingUtil.setCopyrightMessage(t)}
                                </Paragraph>
                            </form>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <div className="mt-5 hcl-text-center">
                                <p>{t('signInText')}</p>
                            </div>
                            <div className="hcl-text-center mt-5">
                                <Button className="hcl-primary" style={{ background: rebrandingUtil.setButtonColor() }} onClick={() => handleLogin()}>{t('signIn')}</Button>
                            </div>
                            <Paragraph className="hcl-type-legal mt-4 hcl-text-center">
                                &copy; {rebrandingUtil.setCopyrightMessage(t)}
                            </Paragraph>
                        </UnauthenticatedTemplate>
                    </div>
                </div>
                {showQRModal && <QRModal qrUrl={qrImg} setModalClose={setModalClose.bind(this)} />}
            </section>
        </React.Fragment>
    )
}

export default EnrollWithAzure