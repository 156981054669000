//HTTP status bad request
export const HTTP_BAD_REQUEST = 400

//HTTP status unauthorized
export const HTTP_UNAUTHORIZED = 401

//HTTP status forbidden
export const HTTP_FORBIDDEN = 403

//HTTP status not found
export const HTTP_NOT_FOUND = 404

//HTTP status locked
export const HTTP_LOCKED = 423

//HTTP status internal server error
export const HTTP_INTERNAL_SERVER_ERROR = 500

//HTTP status bad gateway
export const HTTP_BAD_GATEWAY = 502

//HTTP status too many requests
export const HTTP_TOO_MANY_REQUESTS = 429

//HTTP failed dependency, used for third party dependency failure
export const HTTP_FAILED_DEPENDENCY = 424
//Error Code Map 
export const ErrorCodeMap = {
    Environment_Variable_Missing: "environmentVariableMissing",
    SAML_Token_Expired: "samlTokenExpired",
    Token_Generation_Failed: "tokenGenerationFailed",
    User_Authentication_Failed: "userAuthenticationFailed"
}
